<template>
  <div class="text-center">
    <v-dialog
      :value="addCustomer"
      width="1000"
      scrollable
      :persistent="loading"
      @click:outside="closeDialogFunction"
    >
      <v-overlay v-if="errorMessage.length" />
      <v-alert
        v-if="errorMessage.length"
        color="secondary"
        dismissible
        style="position: absolute; z-index: 10; left: 50%; top: 50%; max-width: 350px; transform: translate(-40%, 50%);"
        type="error"
        @input="closeError"
      >
        {{ errorMessage }}
      </v-alert>
      <v-card>
        <v-card-title class="text-h5 secondary-background white--text">
          <v-icon
            class="mr-3"
            medium
            color="#ffa849"
          >
            mdi-account-multiple-plus
          </v-icon>
          Přidat zákazníka
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <v-expand-transition mode="out-in">
                <div
                  v-if="selectedView === 1"
                  key="1"
                  class="text--dark-gray mt-5"
                >
                  <div class="headline  ">
                    Firma
                  </div>
                  <v-row class="ml-2 mt-1">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="companyName"
                        :rules="rules"
                        color="tertiary"
                        :disabled="loading"
                        outlined
                        style="margin-bottom: -30px;"
                        prepend-icon="mdi-card-account-details"
                        dense
                        label="Název firmy"
                      />
                    </v-col>
                    <v-col

                      cols="12"
                      md="6"
                    >
                      <div v-if="!uploadingLogo && !companyLogoLink">
                        <v-file-input
                          v-model="companyLogo"
                          :disabled="loading"
                          accept="image/png, image/jpeg, image/bmp"
                          prepend-icon="mdi-image"
                          color="tertiary"
                          label="Logo firmy"
                          outlined
                          dense
                        />
                      </div>
                      <v-progress-circular
                        v-else-if="uploadingLogo"
                        style="margin: 0 auto;"
                        indeterminate
                        color="primary"
                      />
                      <div
                        v-else
                        style="position: relative; max-height: 45px; max-width: 200px; margin: 0 auto;"
                      >
                        <v-img

                          :src="fileURL + companyLogoLink"
                          style="max-height: 45px; max-width: 200px; margin: 0 auto;"
                        />
                        <v-icon
                          color="red"
                          small
                          style="position: absolute; top: -5px; right: -20px;"
                          @click="deleteCompanyLogo"
                        >
                          mdi-close
                        </v-icon>
                      </div>
                    </v-col>
                  </v-row>
                  <div class="headline">
                    Adresa
                  </div>
                  <v-row class="ml-2 mt-1">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="companyStreet"
                        color="tertiary"
                        :disabled="loading"
                        outlined
                        dense
                        prepend-icon="mdi-map-marker"
                        label="Ulice"
                        style="margin-bottom: -30px;"
                        :rules="rules"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="companyCity"
                        color="tertiary"
                        outlined
                        :disabled="loading"
                        dense
                        prepend-icon="mdi-city"
                        label="Město"
                        style="margin-bottom: -30px;"
                        :rules="rules"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="ml-2 mt-1">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="companyCountry"
                        color="tertiary"
                        outlined
                        :disabled="loading"
                        dense
                        prepend-icon="mdi-flag"
                        label="Země"
                        style="margin-bottom: -30px;"
                        :rules="rules"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="companyPostCode"
                        :disabled="loading"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-code-array"
                        style="margin-bottom: -30px;"
                        label="PSČ"
                        :rules="rules"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="ml-2 mt-1 mb-1">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="ic"
                        :disabled="loading"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-numeric"
                        label="IČ"
                        style="margin-bottom: -30px;"
                        :rules="rules"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="dic"
                        color="tertiary"
                        :disabled="loading"
                        outlined
                        dense
                        prepend-icon="mdi-counter"
                        style="margin-bottom: -30px;"
                        label="DIČ"
                        :rules="rules"
                      />
                    </v-col>
                  </v-row>
                  <div class="headline  ">
                    Zodpovědná osoba
                  </div>
                  <v-row class="ml-2 mt-1 ">
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="userName"
                        color="tertiary"
                        :disabled="loading"
                        outlined
                        dense
                        prepend-icon="mdi-account"
                        style="margin-bottom: -30px;"
                        label="Jméno"
                        :rules="rules"
                      />
                    </v-col>
                    <!-- <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="customerSurname"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-account"
                        style="margin-bottom: -30px;"
                        label="Přijmení"
                        :rules="rules"
                      />
                    </v-col> -->
                  </v-row>
                  <v-row class="ml-2 mt-1 mb-1">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="userEmail"
                        color="tertiary"
                        outlined
                        :disabled="loading"
                        dense
                        prepend-icon="mdi-email"
                        style="margin-bottom: -30px;"
                        label="E-mail"
                        :rules="rules"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="userPhoneNumber"
                        color="tertiary"
                        outlined
                        :disabled="loading"
                        dense
                        prepend-icon="mdi-phone"
                        style="margin-bottom: -30px;"
                        label="Tel. číslo"
                        :rules="rules"
                      />
                    </v-col>
                  </v-row>
                  <div class="headline">
                    Poznámky
                  </div>
                  <v-row class="ml-2 mt-1 mb-1">
                    <v-col cols="12">
                      <v-textarea
                        v-model="notes"
                        color="tertiary"
                        :disabled="loading"
                        outlined
                        no-resize
                        height="80px"
                        label="Poznámky"
                        value="Zde je prostor pro doplnění, případné poznámky."
                      />
                    </v-col>
                  </v-row>

                  <div
                    class="headline"
                    style="margin-top: -30px;"
                  >
                    Soubory
                  </div>
                  <v-row
                    class="ml-2 mt-1 mb-1"
                    style="margin-bottom: -45px;"
                  >
                    <v-col cols="12">
                      <v-file-input
                        v-model="files"
                        :disabled="loading"
                        color="tertiary"
                        counter
                        label="File input"
                        multiple
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>
                </div>
                <div
                  v-else-if="selectedView === 2"
                  key="2"
                >
                  <v-row class="mt-1 mb-1">
                    <v-col class="text-right">
                      <v-btn
                        :loading="loading"
                        color="tertiary"
                        dark
                        @click="selectedView = 4"
                      >
                        <v-icon class="mr-2">
                          mdi-plus
                        </v-icon>
                        Priradit stroj
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row
                    v-if="customerMachines.length"
                    class="mt-2"
                  >
                    <v-col
                      v-for="(machine, i) in customerMachines"
                      :key="i"
                      cols="3"
                      class="machine-tile"
                    >
                      <div style="display: inline-block; text-align: center; width: 30%;">
                        <img
                          style="max-height: 80px;"
                          :src="fileURL + machine.machineImage"
                        >
                      </div>
                      <div
                        style="display: inline-block; padding-left: 10px; width: 70%; vertical-align:top;"
                      >
                        <div class="font-weight-bold">
                          {{ i+1 }}. {{ machine.machineType }}
                        </div>
                        <div>
                          {{ machine.machineModel }}
                        </div>
                        <div>
                          {{ machine.machineSerialNumber }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="!customerMachines.length"
                    class="mt-5"
                    style="height: 100px"
                  >
                    <v-col class="text-center headline">
                      Priradte zakaznikovi stroje
                    </v-col>
                  </v-row>
                  <v-dialog
                    v-model="addCustomerWithoutMachines"
                    max-width="300px"
                  >
                    <v-card>
                      <v-card-title>
                        Creating user
                      </v-card-title>
                      <v-card-text>
                        <div v-if="!loading">
                          <p>Opravdu chcete vytvořit uživatele?</p>
                          <p>Nový uživatel nemá přiřazené žádné stroje!</p>
                        </div>
                        <div v-else-if="loading">
                          Načítání...
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn

                          :disabled="loading"
                          text
                          @click="addCustomerWithoutMachines = false"
                        >
                          Zrušit
                        </v-btn>
                        <v-btn

                          :disabled="loading"
                          color="tertiary"
                          text
                          @click="submitWithoutMachines()"
                        >
                          Vytvořit zákazníka
                        </v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
                <div
                  v-else-if="selectedView === 3"
                  key="2"
                >
                  <div class="headline text-center mt-5">
                    Zakaznik vytvoren
                  </div>
                  <div class="mt-5 text-center">
                    <span style="font-size: 16px;">Prihlasovaci udaje:</span><br>
                    Jmeno: {{ userEmail }}<br>
                    Heslo: {{ userPassword }}
                  </div>
                  <div class="text-center mt-5">
                    <v-btn
                      color="tertiary"
                      :loading="loading"
                      :disabled="loading"
                      dark
                      @click="sendCustomerHisDetails"
                    >
                      <div v-if="!loading && !detailsSentToCustomer">
                        Odeslat email zakaznikovi
                      </div>
                      <div v-else-if="loading && !detailsSentToCustomer">
                        <v-progress-circular
                          style="margin: 0 auto;"
                          indeterminate
                          color="white"
                        />
                      </div>
                      <div v-else-if="!loading && detailsSentToCustomer">
                        Odeslano!
                      </div>
                    </v-btn>
                  </div>
                </div>
                <div v-else-if="selectedView === 4">
                  <v-row
                    class="mt-3 justify-center align-center"
                  >
                    <v-col
                      v-for="machine in allMachines"
                      :key="machine.id"
                      cols="3"
                      class="machine-tile"
                      style="vertical-align: middle; cursor: pointer;"
                      @click="selectMachineToCustomer(machine)"
                    >
                      <div style="display: inline-block; text-align: center; width: 50%;">
                        <img
                          style="max-height: 130px;"
                          :src="fileURL + machine.machineImage"
                        >
                      </div>
                      <div
                        style="display: inline-block; padding-left: 10px; width: 50%; vertical-align:top;"
                      >
                        <div class="font-weight-bold">
                          {{ machine.machineType }}
                        </div>
                        <div>
                          {{ machine.machineModel }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="!allMachines.length">
                    <v-col style="text-align:center">
                      <div>
                        <v-icon
                          color="tertiary"
                          style="font-size: 45px;"
                        >
                          mdi-database-remove-outline
                        </v-icon>
                      </div>
                      <div class="mt-5">
                        Nebyly nalezeny žádné stoje
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div
                  v-else-if="selectedView === 5"
                  key="5"
                >
                  <v-row class="mt-1">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="selectedMachine.machineType"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-cog-outline"
                        style="margin-bottom: -30px;"
                        label="Typ stoje"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="selectedMachine.machineModel"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-content-save-cog-outline"
                        style="margin-bottom: -30px;"
                        label="Model stroje"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div
                        style="padding: 10px;"
                      >
                        <div style="width: 100%; text-align: center;">
                          <img
                            :src="fileURL + selectedMachine.machineImage"
                            style="max-height: 150px;"
                          >
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <div class="headline black--text mt-5">
                    Seriové číslo
                  </div>
                  <v-row class="mt-1">
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="selectedMachine.machineSerialNumber"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-numeric"
                        style="margin-bottom: -30px;"
                        label="Sériové číslo"
                      />
                    </v-col>
                  </v-row>
                  <div class="headline black--text mt-5">
                    Datum
                  </div>
                  <v-row class="mt-1">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="installationDate"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-calendar-range"
                        style="margin-bottom: -30px;"
                        label="Datum instalace"
                        @click="openPickerOne"
                      />
                      <v-date-picker
                        v-if="pickerOne"
                        v-model="installationDate"
                        locale="sk-SK"
                        elevation="2"

                        header-color="#3d5a81"
                        color="tertiary"

                        class="position-absolute"
                        @input="pickerOne=false"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="dateOfManufacture"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-calendar-range"
                        style="margin-bottom: -30px;"
                        label="Datum výroby"
                        @click="openPickerTwo"
                      />
                      <v-date-picker
                        v-if="pickerTwo"
                        v-model="dateOfManufacture"
                        locale="sk-SK"
                        elevation="2"
                        header-color="#3d5a81"

                        color="tertiary"
                        class="position-absolute"

                        @input="pickerTwo=false"
                      />
                    </v-col>
                  </v-row>
                  <div class="headline black--text mt-5">
                    Informace
                  </div>
                  <v-row class="mt-1">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="selectedMachine.machineCycles"
                        color="tertiary"
                        outlined
                        dense
                        min="0"
                        type="number"
                        prepend-icon="mdi-counter"
                        style="margin-bottom: -30px;"
                        label="Počet cyklů"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="selectedMachine.machineIP"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-laptop"
                        style="margin-bottom: -30px;"
                        label="IP Adresa"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="selectedMachine.machineShelves"
                        color="tertiary"
                        outlined
                        dense
                        number
                        prepend-icon="mdi-numeric"
                        style="margin-bottom: -30px;"
                        label="Počet polic"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="selectedMachine.serviceEveryXDays"
                        color="tertiary"
                        outlined
                        dense
                        type="number"
                        number
                        prepend-icon="mdi-account-wrench"
                        style="margin-bottom: -30px;"
                        label="Servis kazdych (dnu)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="selectedMachine.serviceEveryXCycles"
                        color="tertiary"
                        outlined
                        type="number"
                        dense
                        number
                        prepend-icon="mdi-account-wrench"
                        style="margin-bottom: -30px;"
                        label="Anebo Servis kazdych (pocet cyklu)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="selectedMachine.serviceContract"
                        color="tertiary"
                        outlined
                        type="text"
                        dense
                        prepend-icon="mdi-file-sign"
                        style="margin-bottom: -30px;"
                        label="Servisni smlouva"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-checkbox
                        v-model="selectedMachine.additionalAccessoriesEnabled"
                        label="Doplňková výbava"
                        color="tertiary"
                      />
                      <v-combobox
                        v-if="selectedMachine.additionalAccessoriesEnabled === true"
                        v-model="selectedMachine.machineAccessories"
                        :items="machineAccessoriesData"
                        chips
                        elevation="0"
                        label="Doplňková výbava"
                        multiple
                        outlined
                        dense
                        prepend-icon="mdi-filter-variant"
                      >
                        <template v-slot:selection="{ attrs, item, select, selected }">
                          <v-chip
                            color="secondary"
                            dark
                            style="margin-top: 5px;"
                            small
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            @click="select"
                            @click:close="removeMachineAccessory(item)"
                          >
                            <strong>{{ item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                      <v-checkbox
                        v-model="addressIsDifferent"
                        label="Adresa stroje je jina nez adresa zakaznika"
                        color="tertiary"
                      />

                      <div v-if="addressIsDifferent">
                        <v-row class="mt-1">
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              v-model="street"
                              color="tertiary"
                              :disabled="loading"
                              outlined
                              dense
                              prepend-icon="mdi-map-marker"
                              label="Ulice"
                              style="margin-bottom: -30px;"
                              :rules="rules"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              v-model="city"
                              color="tertiary"
                              outlined
                              :disabled="loading"
                              dense
                              prepend-icon="mdi-city"
                              label="Město"
                              style="margin-bottom: -30px;"
                              :rules="rules"
                            />
                          </v-col>
                        </v-row>
                        <v-row class="mt-1">
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              v-model="country"
                              color="tertiary"
                              outlined
                              :disabled="loading"
                              dense
                              prepend-icon="mdi-flag"
                              label="Země"
                              style="margin-bottom: -30px;"
                              :rules="rules"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              v-model="postcode"
                              :disabled="loading"
                              color="tertiary"
                              outlined
                              dense
                              prepend-icon="mdi-code-array"
                              style="margin-bottom: -30px;"
                              label="PSČ"
                              :rules="rules"
                            />
                          </v-col>
                        </v-row>
                      </div>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <div class="mt-5">
                            <v-text-field
                              v-model="responsiblePerson"
                              prepend-icon="mdi-account"
                              color="tertiary"
                              outlined
                              dense
                              label="Zodpovedna osoba"
                            />
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <div class="mt-5">
                            <v-text-field
                              v-model="systechOrderNumber"
                              color="tertiary"
                              prepend-icon="mdi-receipt"
                              outlined
                              dense
                              label="Cislo objednavky"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                      <div style="position: relative; margin-top: 20px;">
                        <v-file-input
                          v-model="machineFiles"
                          color="tertiary"
                          class="custom-label-color"
                          label="Pridat nové soubory"
                          multiple
                          outlined
                          :disabled="uploading"
                          dense
                        />
                        <v-progress-circular
                          v-if="uploading"
                          style="margin: 0 auto; position: absolute; top: 4px;; left: 50%;"
                          color="tertiary"
                          indeterminate
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            v-if="selectedView === 1"
            color="primary"

            :disabled="loading"
            text
            @click="closeDialog"
          >
            Zrušit
          </v-btn>
          <v-btn
            v-else
            color="primary"
            :disabled="loading"
            text
            @click="selectedView = 1"
          >
            Zpet
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="selectedView === 1"
            color="tertiary"
            :loading="loading"
            :disabled="loading || !companyStreet || !companyCity || !companyPostCode || !companyCountry || !userName || !userEmail"
            text
            @click="selectedView = 2; responsiblePerson = userName;"
          >
            <div>
              Dalsi krok
            </div>
          </v-btn>
          <v-btn
            v-if="selectedView === 5"
            color="tertiary"
            text
            :loading="loading"
            :disabled="loading"
            @click="addMachineToCustomer"
          >
            <div>
              Pridat stroj
            </div>
          </v-btn>
          <v-btn
            v-if="selectedView === 2 && !loading && customerMachines.length"
            :loading="loading"
            color="tertiary"
            :disabled="loading"
            text
            @click="submit"
          >
            <div v-if="!loading">
              Vytvořit zakaznika
            </div>
          </v-btn>
          <v-btn
            v-if="selectedView === 2 && !loading && !customerMachines.length"
            :loading="loading"
            color="tertiary"
            :disabled="loading"
            text
            @click="addCustomerWithoutMachines = true"
          >
            <div v-if="!loading">
              Vytvořit zakaznika
            </div>
          </v-btn>
          <v-btn
            v-if="selectedView === 3"
            color="tertiary"
            text
            :loading="loading"
            :disabled="loading"
            @click="closeDialog"
          >
            Hotovo
          </v-btn>
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="tertiary"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import obce from '../Library/OBCE.json'
import dayjs from 'dayjs'
export default {
  props: {
    addCustomer: Boolean
  },
  data () {
    return {
      obce,
      hover: false,
      selectedView: 1,
      companyStreet: '',
      companyCity: '',
      companyPostCode: '',
      companyCountry: 'Slovensko',
      companyName: '',
      ic: '',
      dic: '',
      userName: '',
      userEmail: '',
      userPhoneNumber: '',
      customerMachines: [],
      notes: '',
      userPassword: '',
      pickerOne: false,
      pickerTwo: false,
      installationDate: dayjs().format('YYYY-MM-DD'),
      dateOfManufacture: dayjs().format('YYYY-MM-DD'),
      machineAccessoriesData: [],
      files: null,
      uploading: false,
      machineFiles: null,
      companyLogo: null,
      companyLogoLink: null,
      uploadedFilesLinks: null,
      loading: false,
      errorMessage: '',
      uploadingLogo: false,
      rules: [],
      allMachines: [],
      selectedMachine: {},
      addressIsDifferent: false,
      addCustomerWithoutMachines: false,
      street: '',
      city: '',
      country: '',
      postcode: '',
      responsiblePerson: '',
      systechOrderNumber: '',
      detailsSentToCustomer: false,
      fileURL: process.env.VUE_APP_API_FILES
    }
  },
  watch: {
    addCustomer: function (val) {
      if (val) {
        this.userPassword = Array(10).fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz').map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join('')
        this.getAllMachines()
        this.pullMachineDetails()
      }
    },
    companyLogo: function (val) {
      if (val !== null) {
        this.uploadingLogo = true
        const fileData = new FormData()
        fileData.append('logo', this.companyLogo)
        this.$store.dispatch('uploadFile', fileData)
          .then(resp => {
            // console.log(resp.data)
            this.companyLogoLink = resp.data
            this.uploadingLogo = false
          })
          .catch(err => {
            this.errorMessage = err.response.data
            this.uploadingLogo = false
            this.companyLogo = null
          })
      }
    },
    companyCity: function (val) {
      const city = val

      const index = obce.findIndex(item => item.OBEC === city)
      if (index !== -1) {
        this.companyPostCode = obce[index].PSC
      }
    }
  },
  mounted () {
    // console.log(fileURL)
  },
  methods: {
    closeDialog () {
      this.clearData()
      this.$emit('close')
      this.$store.dispatch('getAllCustomers').then(() => {
        this.$store.dispatch('adminGetCustomerMachines')
      })
        .catch(err => {
          this.errorMessage = err.response.data
        })
    },
    deleteCompanyLogo () {
      this.companyLogoLink = ''
      this.companyLogo = null
    },
    submit () {
      this.loading = true
      const newUser = {
        address: {
          street: this.companyStreet,
          city: this.companyCity,
          postCode: this.companyPostCode,
          country: this.companyCountry
        },
        userName: this.userName,
        userEmail: this.userEmail,
        userPhoneNumber: this.userPhoneNumber,
        company: this.companyName,
        ic: this.ic,
        dic: this.dic,
        notes: this.notes,
        password: this.userPassword,
        machines: this.customerMachines
      }
      if (this.files !== null) {
        this.uploading = true
        const fileData = new FormData()
        for (const file of this.files) {
          fileData.append('files', file)
        }
        this.$store.dispatch('uploadFiles', fileData)
          .then(resp => {
            this.uploading = true
            // if file uploaded successfully you will receive array of links then needs to be passed to createNewCustomer
            // console.log(resp.data)
            this.createNewCustomer(newUser, resp.data)
          })
          .catch(err => {
            this.errorMessage = err.response.data
            this.uploading = true
            this.loading = false
            this.files = null
          })
      } else {
        this.createNewCustomer(newUser, [])
      }
    },
    submitWithoutMachines () {
      this.loading = true
      const newUser = {
        address: {
          street: this.companyStreet,
          city: this.companyCity,
          postCode: this.companyPostCode,
          country: this.companyCountry
        },
        userName: this.userName,
        userEmail: this.userEmail,
        userPhoneNumber: this.userPhoneNumber,
        company: this.companyName,
        ic: this.ic,
        dic: this.dic,
        notes: this.notes,
        password: this.userPassword,
        machines: []
      }

      this.createNewCustomer(newUser, [])
    },

    createNewCustomer (newUserData, fileLinks) {
      newUserData.files = fileLinks
      newUserData.companyLogo = this.companyLogoLink
      this.$store.dispatch('createNewCustomer', newUserData)
        .then(resp => {
          this.selectedView = 3
          this.loading = false
        })
        .catch(err => {
          this.errorMessage = err.response.data
          this.loading = false
        })
    },
    clearData () {
      this.companyStreet = ''
      this.companyCity = ''
      this.companyPostCode = ''
      this.companyCountry = ''
      this.companyName = ''
      this.customerMachines = []
      this.ic = ''
      this.dic = ''
      this.userName = ''
      this.userEmail = ''
      this.userPhoneNumber = ''
      this.notes = ''
      this.userPassword = ''
      this.selectedView = 1
      this.files = null
      this.companyLogo = null
      this.companyLogoLink = null
      this.uploadedFilesLinks = null
      this.password = ''
    },
    closeDialogFunction () {
      if (!this.loading) {
        this.closeDialog()
      }
    },
    closeError () {
      this.errorMessage = ''
    },
    getAllMachines () {
      this.$store.dispatch('getAllMachines')
        .then(resp => {
          this.allMachines = resp.data
        })
        .catch(err => {
          this.errorMessage = err.response.data
        })
    },
    pullMachineDetails () {
      this.$store.dispatch('getMachineDetails')
        .then(resp => {
          this.machineAccessoriesData = resp.data.accessories
          // console.log(resp.data.accessories)
        })
        .catch(err => {
          this.errorMessage = err.response.data
        })
    },
    selectMachineToCustomer (machine) {
      this.selectedMachine = { ...machine }
      this.selectedView = 5
    },
    openPickerOne () {
      this.pickerOne = !this.pickerOne
    },
    openPickerTwo () {
      this.pickerTwo = !this.pickerTwo
    },
    addMachineToCustomer () {
      this.loading = true
      const selectedMachine = this.selectedMachine
      let address = {
        street: this.companyStreet,
        city: this.companyCity,
        postCode: this.companyPostCode,
        country: this.companyCountry
      }

      if (this.addressIsDifferent) {
        address = {
          street: this.street,
          city: this.city,
          postCode: this.postcode,
          country: this.country
        }
      }
      selectedMachine.responsiblePerson = this.responsiblePerson
      selectedMachine.systechOrderNumber = this.systechOrderNumber
      selectedMachine.machineAddress = address
      selectedMachine.customer = this.companyName
      if (this.machineFiles !== null) {
        this.uploading = true
        const fileData = new FormData()
        for (const file of this.machineFiles) {
          fileData.append('files', file)
        }
        this.$store.dispatch('uploadFiles', fileData)
          .then(resp => {
            // if file uploaded successfully you will receive array of links then needs to be passed to createNewCustomer
            selectedMachine.files.push(...resp.data)
            this.customerMachines.push(selectedMachine)
            this.selectedView = 2
            this.uploading = false
            this.loading = false
            this.selectedMachine = {}
          })
          .catch(err => {
            this.errorMessage = err.response.data
            this.loading = false
            this.uploading = false
            this.files = null
          })
      } else {
        this.customerMachines.push(selectedMachine)
        this.selectedView = 2
        this.selectedMachine = {}
        this.loading = false
      }
    },
    removeMachineAccessory (item) {
      const index = this.selectedMachine.machineAccessories.findIndex(i => i === item)
      this.selectedMachine.machineAccessories.splice(index, 1)
    },
    sendCustomerHisDetails () {
      this.loading = true
      this.$store.dispatch('sendCustomerHisDetails', { userName: this.userName, password: this.userPassword })
        .then(() => {
          this.loading = false
          this.detailsSentToCustomer = true
        })
    }
  }
}
</script>

<style scoped>
.machine-tile {
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin: 5px;
}
.machine-tile:hover {
  border-color: #ff5722;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
</style>
