<template>
  <v-container class="page-container d-flex">
    <v-row no-gutters>
      <v-col
        class="login-box justify-center"
      >
        <div
          class="text--gray"
          style="max-width: 800px; margin: 35px auto;"
        >
          <div class="headline mb-5">
            Dokonceni registrace
          </div>

          <v-text-field
            v-model="user.userEmail"
            label="E-mail"
            outlined
            dense
            type="email"

            required
          />
          <v-text-field
            v-model="user.userName"
            label="Jmeno uzivatele"
            outlined
            dense
            type="text"

            required
          />
          <!-- <v-text-field
            v-model="token"
            label="E-mail"
            outlined
            dense
            type="email"
            required
          /> -->
          <v-text-field
            v-model="password"
            label="Heslo"
            outlined
            dense
            type="password"
            required
          />
          <v-text-field
            v-model="passwordConfirm"
            label="Potvrdit heslo"
            outlined
            dense
            type="password"
            required
          />
          <v-btn
            :loading="loading"
            class="black--text float-left"
            outlined
            :disabled="loading"
            @click="BtnBack"
          >
            Zpět
          </v-btn>
          <v-btn
            :loading="loading"
            class="white--text float-right"
            color="#ff5722"
            :disabled="password !== passwordConfirm || !password || loading"
            @click="completeRegistration"
          >
            Odeslat
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'CompleteRegistration',
  data () {
    return {
      loading: false,
      email: '',
      token: '',
      password: '',
      passwordConfirm: '',
      user: {}
    }
  },
  created () {
    this.token = this.$route.params.token
    // console.log(this.token)
    this.pullDataForRegistrationCompletion()
  },
  methods: {
    BtnBack () {
      this.$router.push('/')
    },
    pullDataForRegistrationCompletion () {
      this.$store.dispatch('pullDataForRegistrationCompletion', { token: this.token })
        .then(resp => {
          // console.log(resp.data)
          this.user = resp.data
        })
    },
    completeRegistration () {
      this.$store.dispatch('completeRegistration', { userName: this.user.userName, userEmail: this.user.userEmail, userAddress: this.user.userAddress, company: this.user.company, userPassword: this.password, token: this.token })
        .then(resp => {
          this.$router.push('/')
        })
    }
  }
}
</script>

<style scoped>

</style>
