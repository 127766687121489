<template>
  <div class="text-center">
    <v-dialog
      :value="documents"
      width="900"
      :persistent="loading || uploading ? true : false"
      @click:outside="closeDialogFunction"
    >
      <v-alert
        v-if="errorMessage.length"
        color="secondary"
        dismissible
        style="position: absolute; z-index: 10; left: 50%; top: 50%; max-width: 350px; transform: translate(-40%, 50%);"
        type="error"
        @input="closeError"
      >
        {{ errorMessage }}
      </v-alert>
      <v-card>
        <v-card-title class="text-h5 secondary-background white--text">
          <v-icon
            class="mr-3"
            medium
            color="#ffa849"
          >
            mdi-file-document-multiple
          </v-icon>
          Dokumenty
        </v-card-title>
        <v-card-text>
          <v-row
            class="ml-2 mt-1 mb-1"
            style="margin-bottom: -45px;"
          >
            <v-col
              style="text-align: right;"
              cols="12"
            >
              <v-file-input
                v-model="newFile"
                color="tertiary"
                counter
                label="Soubory"
                outlined
                dense
              />
              <v-text-field
                v-model="selectedCategory"
                label="Kategorie Noveho Dokumentu"
                prepend-icon="mdi-text-box"
              />
              <v-btn
                :disabled="!selectedCategory || !newFile || uploading"
                color="green"
                :loading="uploading"
                :dark="!selectedCategory || uploading ? false : true"
                @click="uploadNewFile"
              >
                Nahrat dokument
              </v-btn>
            </v-col>
          </v-row>
          <v-divider />

          <v-row>
            <v-col class="mt-5">
              <div
                v-for="group, category in groupedDocuments"
                :key="category"
              >
                <div
                  color="primary"
                  style="font-weight: bold; font-size: 16px; margin-top: 10px;"
                >
                  {{ category }}
                </div>

                <div
                  v-for="file in group"
                  :key="file.id"
                  style="cursor: pointer;"
                  class="mt-1"
                >
                  <v-icon :color="mapFileExtension(file.documentLink).color">
                    {{ mapFileExtension(file.documentLink).icon }}
                  </v-icon>
                  <div
                    style="margin-top:5px; display: inline-block; text-decoration: underline;"
                    @click="openLink(file.documentLink)"
                  >
                    {{ mapFileName(file.documentLink) }}
                  </div>
                  <v-icon
                    v-if="selectedEdit"
                    class="ml-1"
                    color="tertiary"
                    @click="deleteDocument(file.id)"
                  >
                    mdi-close-box
                  </v-icon>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="primary"
            text

            :disabled="loading"
            @click="closeDialogFunction"
          >
            Zavřít
          </v-btn>
          <v-spacer />
          <v-btn
            color="tertiary"
            text
            :loading="loading"
            :disabled="loading"
            @click="selectedEdit = !selectedEdit"
          >
            {{ selectedEditText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {

  props: {
    documents: Boolean
  },
  data () {
    return {
      loading: false,
      newFile: null,
      selectedCategory: null,
      uploading: false,
      errorMessage: '',
      selectedEdit: false,
      selectedEditText: 'Upravit',
      files: []
    }
  },
  computed: {
    groupedDocuments () {
      return groupBy(this.files, 'category')
    }
  },
  watch: {
    documents: function (val) {
      if (val) {
        this.pullDocuments()
      }
    },
    selectedEdit () {
      if (this.selectedEdit) {
        this.selectedEditText = 'Uložit'
      } else {
        this.selectedEditText = 'Upravit'
      }
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close')
      this.selectedEdit = false
    },
    closeDialogFunction () {
      // console.log(this.loading, this.uploading)
      if (!this.loading || !this.uploading) {
        this.closeDialog()
      }
    },
    openLink (link) {
      window.open(`https://systechgroup-service.warberryapps.com/files/${link}`, '_blank')
    },
    pullDocuments () {
      this.$store.dispatch('pullDocuments')
        .then(resp => {
          this.files = resp.data
        })
    },
    uploadNewFile () {
      this.unsavedItems = true
      this.uploading = true

      const fileData = new FormData()
      fileData.append('files', this.newFile)
      this.$store.dispatch('uploadFiles', fileData)
        .then(resp => {
          const file = resp.data[0]
          this.$store.dispatch('saveNewDocuments', { documentLink: file, category: this.selectedCategory })
            .then(resp => {
              // console.log(typeof this.files)
              const data = resp.data
              // console.log(data)
              this.files.push(data)
            })
          this.uploading = false

          this.newFile = null
          this.selectedCategory = null
        })
        .catch(err => {
          this.errorMessage = err.response.data
          this.uploading = false

          this.newFile = null
        })
    },
    deleteDocument (id) {
      this.loading = true
      this.$store.dispatch('deleteDocument', id)
        .then(() => {
          const index = this.files.findIndex(item => item.id === id)
          // console.log(index)
          this.files.splice(index, 1)
          this.loading = false
        })
    },
    mapFileExtension (file) {
      const splitted = file.split('.')
      const extension = splitted.pop().toLowerCase()
      // console.log(extension)
      if (extension === 'pdf') {
        return { icon: 'mdi-file-pdf-box', color: 'red' }
      } else if (extension === 'doc' || extension === 'docx' || extension === 'txt') {
        return { icon: 'mdi-file-word-box', color: 'blue' }
      } else if (extension === 'xls' || extension === 'xlsx' || extension === 'csv') {
        return { icon: 'mdi-file-excel-box', color: 'green' }
      } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
        return { icon: 'mdi-file-image', color: 'purple' }
      } else {
        return { icon: 'mdi-file', color: 'gray' }
      }
    },
    mapFileName (file) {
      const string = file.substring(19)
      const length = 22
      return string.length > length
        ? string.substring(0, length) + '...'
        : string
    }
  }
}

function groupBy (array, key) {
  const result = {}
  array.forEach(item => {
    if (!result[item[key]]) {
      result[item[key]] = []
    }
    result[item[key]].push(item)
  })

  return result
}
</script>
