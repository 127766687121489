<template>
  <v-container class="page-container d-flex">
    <v-row no-gutters>
      <v-col
        class="login-box justify-center"
      >
        <v-alert
          v-if="errorMessage.length"
          color="secondary"
          dismissible
          style="position: absolute; z-index: 10; left: 50%; top: 50%; max-width: 350px; transform: translate(-40%, 50%);"
          type="error"
          @input="closeError"
        >
          {{ errorMessage }}
        </v-alert>
        <div>
          <v-card
            elevation="0"
            class="padding: 40px;"
            style="max-width: 500px; margin: 35px auto; background: white;"
          >
            <v-card-text
              v-if="selectedView === '1'"
              class="padding: 30px; "
            >
              <div class="headline mb-5">
                Zapomenuté heslo
              </div>

              <v-text-field
                v-model="email"
                label="E-mail"
                outlined
                dense
                type="email"
                required
              />
            </v-card-text>
            <v-card-text v-else-if="selectedView === '2'">
              <div>
                Prosim zkontrolujte Vas email a pokracujte podle instrukci.
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                :disabled="loading"
                :dark="loading ? false : true"
                @click="BtnBack"
              >
                Zpět
              </v-btn>
              <v-spacer />
              <v-btn
                v-if="selectedView === '1'"
                :loading="loading"
                :disabled="loading"
                :dark="loading ? false : true"
                color="tertiary"
                @click="forgotPasswordRequest"
              >
                <v-progress-circular
                  v-if="loading"
                  color="white"
                  indeterminate
                />
                <div v-else>
                  Odeslat
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'ForgotPassword',
  data () {
    return {
      email: '',
      loading: false,
      selectedView: '1',
      errorMessage: ''
    }
  },
  methods: {
    BtnBack () {
      this.$router.push('/')
    },
    forgotPasswordRequest () {
      this.loading = true
      this.$store.dispatch('forgotPasswordRequest', this.email)
        .then(resp => {
          console.log(resp)
          this.selectedView = '2'
          this.loading = false
        })
        .catch(error => {
          this.errorMessage = error.response.data
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
