import Vue from 'vue'
import Vuex from 'vuex'

import jwtDecode from 'jwt-decode'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || null,
    userName: localStorage.getItem('user-name') || null,
    userEmail: localStorage.getItem('user-email') || null,
    address: JSON.parse(localStorage.getItem('user-address')) || null,
    role: localStorage.getItem('r') || null,
    userId: localStorage.getItem('user-id') || null,
    company: localStorage.getItem('company') || null,
    userDetails: {},
    customerMachines: [],
    allPortalUsers: [],
    test: ''
  },
  mutations: {

    loginUser (state, { decodedToken, token }) {
      state.token = token
      state.userName = decodedToken.userName
      state.userEmail = decodedToken.userEmail
      state.address = decodedToken.userAddress
      state.company = decodedToken.company
      state.role = decodedToken.r
      state.userId = decodedToken.userId
    },
    logout (state) {
      state.token = null
      state.userName = null
      state.userEmail = null
      state.address = null
      state.role = null
      state.userId = null
    },
    deleteCustomer (state, customerId) {
      state.customerMachines.forEach((machine, i) => {
        if (machine.userId === customerId) {
          state.customerMachines.splice(index, 1)
        }
      })
      const index = state.allPortalUsers.findIndex(item => item.id === customerId)
      state.allPortalUsers.splice(index, 1)
    },
    deleteCustomerMachine (state, machineId) {
      const index = state.customerMachines.findIndex(item => item.id === machineId)
      state.customerMachines.splice(index, 1)
    },
    addMachineToExistingCustomer (state, data) {
      console.log(data)
      state.customerMachines.push(data)
    },
    getUserData (state, data) {
      state.userDetails = data.userDetails
    },
    getAllCustomerMachines (state, data) {
      state.customerMachines = data
    },
    getMyMachines (state, data) {
      state.customerMachines = data.myMachines
    },
    getAllCustomers (state, data) {
      state.allPortalUsers = data
    },
    saveMyProfile (state, data) {
      state.userName = data.userName
      state.userEmail = data.userEmail
      state.address = data.userAddress
      localStorage.setItem('user-name', data.userName)
      localStorage.setItem('user-email', data.userEmail)
      localStorage.setItem('user-address', JSON.stringify(data.userAddress))
    },
    updateMachineCycles (state, data) {
      const index = state.customerMachines.findIndex(item => item.id === data.machineId)
      state.customerMachines[index].machineCycles = parseInt(data.cycleEntry)
      //  state.customerMachines.push(data)
    }
  },
  getters: {
    loggedIn: state => state.token !== null
  },
  actions: {
    // users
    loginUser ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/login/`, data)
          .then((response) => {
            const decodedToken = jwtDecode(response.data)
            localStorage.setItem('user-name', decodedToken.payload.userName)
            localStorage.setItem('user-email', decodedToken.payload.userEmail)
            localStorage.setItem('user-id', decodedToken.payload.userId)
            localStorage.setItem('r', decodedToken.payload.r)
            localStorage.setItem('user-address', JSON.stringify(decodedToken.payload.userAddress))
            localStorage.setItem('token', response.data)
            localStorage.setItem('company', decodedToken.payload.company)
            commit('loginUser', { decodedToken: decodedToken.payload, token: response.data })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    logout ({ commit }) {
      localStorage.clear()
      commit('logout')
    },
    getUserData ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/users/get-data/${this.state.userId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('getUserData', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getPortalAdmins ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/admin/admins/get-all-admins`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getAllCustomers ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/admin/customers/get-all-customers`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('getAllCustomers', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createNewCustomer ({ commit }, customerData) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/admin/customers/create-new-customer`, customerData, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveEditedCustomer ({ commit }, editedData) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/admin/customers/edit-customer`, editedData, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addMachineToExistingCustomer ({ commit }, { machine, userId }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/admin/machines/add-customer-machine/${userId}`, machine, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            console.log(response)
            commit('addMachineToExistingCustomer', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editedCustomerMachine ({ commit }, editedData) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/admin/machines/edit-customer-machine`, editedData, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteCustomerMachine ({ commit }, machineId) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/admin/machines/delete-customer-machine/${machineId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('deleteCustomerMachine', machineId)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteCustomer ({ commit }, customerId) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/admin/customers/delete-customer/${customerId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('deleteCustomer', customerId)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteService ({ commit }, serviceId) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/user/machines/delete-service-records/${serviceId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            console.log('smazáno')
            // commit('deleteService', serviceId)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteCycle ({ commit }, cycleId) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/user/machines/delete-cycle-records/${cycleId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            console.log('smazáno')
            // commit('deleteService', serviceId)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteAdmin ({ commit }, adminId) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/admin/admins/delete-admin/${adminId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addNewAdmin ({ commit }, adminData) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/admin/admins/create-new-admin`, adminData, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    pullDataForRegistrationCompletion ({ commit }, { token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/register/get-user-to-register/${token}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    completeRegistration ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/register/complete-user-registration/${data.token}`, data)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createMachines ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/admin/machines/create-machine/`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveEditedMachine ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/admin/machines/edit-machine`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getAllMachines ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/admin/machines/all-machines`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    uploadFiles ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/admin/upload/multiple`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    uploadFile ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/admin/upload/single`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getMachineDetails ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/admin/machines/get-machine-accessories`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    adminGetCustomerMachines ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/admin/machines/get-all-customer-machines`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('getAllCustomerMachines', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getCustomerMachines ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/machines/get-my-machines/${this.state.userId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('getMyMachines', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    setCycleRecords ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/machines/new-cycle-records/${data.machineId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
            // console.log(response)
            commit('updateMachineCycles', data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getCycleRecords ({ commit }, machineId) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/machines/cycle-records/${machineId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getServiceRecords ({ commit }, machineId) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/machines/service-records/${machineId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    postServiceRecords ({ commit }, { serviceRecord, machineId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/machines/add-service-records/${machineId}`, serviceRecord, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveMyProfile ({ commit }, editedData) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/users/update-my-profile/${this.state.userId}`, editedData, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            // console.log(response.data)
            commit('saveMyProfile', editedData)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getCurrentSMTPSetting ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/admin/emails/smtp-setting`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateSMTPSetting ({ commit }, settings) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/admin/emails/update-smtp-setting`, settings, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    testSMTPSetting ({ commit }, emailTo) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/admin/emails/test-smtp-setting`, emailTo, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    sendCustomerHisDetails ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/admin/emails/send-customer-his-login-details`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    forgotPasswordRequest ({ commit }, email) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/password/reset-password-email-token/`, { email })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    verifyToken ({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/password/verify-token/${token}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    changePassword ({ commit }, { token, password }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/password/complete-reset-password/${token}`, { token, password })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    pullDocuments ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/admin/documents/get-documents`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveNewDocuments ({ commit }, { documentLink, category }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/admin/documents/save-new-documents`, { documentLink, category }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteDocument ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/admin/documents/delete-document/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteMachine ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/admin/machines/delete-machine/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateNotesForCustomerMachine ({ commit }, { machineId, notes }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/admin/machines/update-notes-to-customer-machine/${machineId}`, { notes }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateFilesForCustomerMachine ({ commit }, { machineId, files }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/admin/machines/update-files-to-customer-machine/${machineId}`, { files }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
})
