<template>
  <div class="text-center">
    <v-dialog
      :value="platformSettings"
      width="900"
      max-height="900"
      @click:outside="closeDialog"
    >
      <v-card>
        <v-card-title
          class="text-h5 secondary-background white--text"
        >
          <v-icon
            class="mr-3"
            medium
            color="#ffa849"
          >
            mdi-cogs
          </v-icon>
          Nastaveni platformy
        </v-card-title>

        <v-progress-linear
          v-if="loading"
          indeterminate
          color="tertiary"
          class="mb-0"
        />

        <v-card-text>
          <v-expand-transition mode="out-in">
            <div
              key="1"
            >
              <div class="mt-3 mb-3 text-h6">
                SMTP Nastaveni
              </div>
              <v-row class="disabled">
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="user"
                    outlined
                    prepend-icon="mdi-account"
                    dense
                    label="SMTP User Name"
                    style="margin-bottom: -10px;"
                  />
                </v-col>

                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="pass"
                    outlined
                    prepend-icon="mdi-key"
                    dense
                    label="SMTP Password"
                    style="margin-bottom: -10px;"
                  />
                </v-col>
              </v-row>
              <v-row class="disabled">
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="host"
                    outlined
                    prepend-icon="mdi-web"
                    dense
                    label="SMTP Host Name (smtp.systechgroup.eu)"
                    style="margin-bottom: -10px;"
                  />
                </v-col>

                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="port"
                    outlined
                    prepend-icon="mdi-numeric"
                    type="number"
                    dense
                    label="SMTP Port"
                    style="margin-bottom: -10px;"
                  />
                </v-col>
              </v-row>
              <v-row class="disabled">
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-checkbox
                    v-model="secure"
                    color="tertiary"
                    prepend-icon="mdi-lock"
                    prepend-icon-color="primary"
                    label="Is the SMTP secured?"
                  />
                </v-col>

                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="from"
                    outlined
                    prepend-icon="mdi-mail"
                    dense
                    label="Systechgroup <support@systechgroup.eu>"
                    style="margin-bottom: -10px;"
                  />
                </v-col>
              </v-row>
              <v-divider />
              <v-row class="mt-5">
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="emailTo"
                    outlined
                    prepend-icon="mdi-mail"
                    dense
                    label="Emailova adresa"
                    style="margin-bottom: -10px;"
                  />
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="tertiary"
                    dark
                    @click="testSMTPSettings"
                  >
                    <div v-if="!loading">
                      Odeslat test email
                    </div>
                    <div v-else>
                      <v-progress-circular
                        style="margin: 0 auto;"
                        indeterminate
                        color="white"
                      />
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
          <v-scroll-y-transition>
            <v-alert
              v-if="errorMessage"
              color="red"
              type="error"
              class="alert"
              dismissible
              style="position: absolute; top: 10px; left: 50%; transform: translate(-50%)"
              border="left"
              @input="closeError"
            >
              {{ errorMessage }}
            </v-alert>
          </v-scroll-y-transition>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="primary"
            text
            @click="closeDialog"
          >
            Zavřít
          </v-btn>
          <v-spacer />
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="primary"
            text
            class="disabled"
            @click="saveSMTPSettings"
          >
            <div v-if="!loading">
              Ulozit
            </div>
            <div v-else>
              <v-progress-circular
                style="margin: 0 auto;"
                indeterminate
                color="tertiary"
              />
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    platformSettings: Boolean
  },
  data () {
    return {
      loading: false,
      loadingSave: false,
      errorMessage: '',
      from: '',
      host: '',
      port: 0,
      secure: false,
      user: '',
      pass: '',
      emailTo: ''
    }
  },
  watch: {
    platformSettings: function (val) {
      if (val) {
        this.pullCurrentSmtpSettings()
      }
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    },
    pullCurrentSmtpSettings () {
      this.loading = true
      this.$store.dispatch('getCurrentSMTPSetting')
        .then(resp => {
          this.from = resp.data.from
          this.host = resp.data.host
          this.port = resp.data.port
          this.secure = resp.data.secure
          this.user = resp.data.user
          this.pass = resp.data.pass
          this.loading = false
        })
        .catch(err => {
          this.errorMessage = 'Něco selhalo, prosím zkuste to znovu ' + err
          this.loading = false
        })
    },
    saveSMTPSettings () {
      this.loading = true
      const data = {
        from: this.from,
        host: this.host,
        port: this.port,
        secure: this.secure,
        user: this.user,
        pass: this.pass
      }
      this.$store.dispatch('updateSMTPSetting', data)
        .then(() => {
          this.loading = false
        })
        .catch(err => {
          this.errorMessage = 'Něco selhalo, prosím zkuste to znovu ' + err
          this.loading = false
        })
    },
    testSMTPSettings () {
      this.loading = true
      this.$store.dispatch('testSMTPSetting', { emailTo: this.emailTo })
        .then(() => {
          this.loading = false
        })
        .catch(err => {
          this.errorMessage = err.response.data
          this.loading = false
        })
    },
    closeError () {
      this.errorMessage = ''
    }
  }
}
</script>

<style scoped>
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
