import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#222831',
        secondary: '#2d4059',
        tertiary: '#ff5722',
        grayBase: '#eeeeee',
        background: '#eeeeee'
      }
    }
  }
})
