<template>
  <v-app id="app">
    <v-app-bar
      app
      color="secondary"
      elevation="0"
      height="72"
      dark
      clipped-right
    >
      <div class="pt-2">
        <img
          style="max-width: 180px; cursor: pointer;"
          src="./assets/logo-white.png"
          @click="$router.go('/')"
        >
      </div>

      <v-spacer />

      <div
        v-if="isLoggedIn"
        class="mr-5"
      >
        {{ initials }}
      </div>
      <v-app-bar-nav-icon
        v-if="isLoggedIn"
        color="orange lighten-4"
        @click="openMenu = !openMenu"
      />
    </v-app-bar>

    <v-navigation-drawer
      v-if="isLoggedIn"
      v-model="openMenu"
      hide-overlay
      clipped
      color="#3d5a81"
      dark
      right
      app
    >
      <v-list>
        <div
          v-for="(item, index) in menuItems"
          :key="index"
        >
          <div v-if="currentUserRole() === item.role || item.role === null">
            <v-divider
              v-if="item.divider"
              class="mt-2"
            />
            <v-list-item
              @click="actionSelection(item.action)"
            >
              <v-icon
                class="mr-3"
                color="orange lighten-4"
              >
                {{ item.icon }}
              </v-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>

    <my-profile
      :open-my-profile="openMyProfile"
      @close="openMyProfile = false"
    />

    <portal-admins
      :open-portal-admins="openPortalAdmins"
      @close="openPortalAdmins = false"
    />
    <add-customer
      :add-customer="addCustomer"
      @close="addCustomer=false"
    />
    <machines-management
      :machines-management="machinesManagement"
      @close="machinesManagement=false"
    />
    <edit-customer
      :edit-customer="editCustomer"
      :company="company"
      @close="editCustomer=false"
    />
    <documents
      :documents="documents"
      @close="documents=false"
    />
    <platform-settings
      :platform-settings="platformSettings"
      @close="platformSettings = false"
    />
    <v-main class="background">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import myProfile from './components/MyProfile.vue'
import portalAdmins from './components/PortalAdmins.vue'
import addCustomer from './components/AddCustomer.vue'
import machinesManagement from './components/MachinesManagement.vue'
import editCustomer from './components/EditCustomer.vue'
import documents from './components/Documents.vue'
import platformSettings from './components/PlatformSettings.vue'

export default {
  name: 'App',
  components: {
    myProfile,
    portalAdmins,
    addCustomer,
    machinesManagement,
    editCustomer,
    documents,
    platformSettings
  },
  data: () => ({
    menuItems: [
      { icon: 'mdi-account-circle-outline', title: 'Můj profil', action: 'openMyProfile', role: null },
      { icon: 'mdi-file-document-multiple-outline', title: 'Dokumenty', action: 'documents', role: 'admin', divider: true },
      { icon: 'mdi-account-supervisor', title: 'Administrátoři', action: 'portalAdmins', role: 'admin' },
      { icon: 'mdi-account-multiple-plus-outline', title: 'Přidat zákazníka', action: 'addCustomer', role: 'admin' },
      { icon: 'mdi-account-edit-outline', title: 'Zákazníci', action: 'editCustomer', role: 'admin' },
      { icon: 'mdi-cog-outline', title: 'Stroje', action: 'machinesManagement', role: 'admin' },
      { icon: 'mdi-cogs', title: 'Nastaveni platformy', action: 'platformSettings', role: 'admin' },
      { icon: 'mdi-logout', title: 'Odhlásit', action: 'logout', role: null, divider: true }
    ],
    openMyProfile: false,
    openMenu: null,
    openPortalAdmins: false,
    addCustomer: false,
    machinesManagement: false,
    editCustomer: false,
    documents: false,
    platformSettings: false,
    company: {}
  }),
  computed: {
    isLoggedIn () {
      return this.$store.getters.loggedIn
    },
    initials () {
      return this.$store.state.userName
    },
    mini () {
      return this.$vuetify.breakpoint.mdAndDown
    }
  },
  mounted () {
    if (this.$store.getters.loggedIn) {
      this.$store.dispatch('getUserData')
    }
  },
  methods: {
    actionSelection (actionName) {
      if (actionName === 'openMyProfile') {
        this.openMyProfile = !this.openMyProfile
      } else if (actionName === 'logout') {
        this.$store.dispatch('logout')
        this.$router.push('/')
      } else if (actionName === 'portalAdmins') {
        this.openPortalAdmins = !this.openPortalAdmins
      } else if (actionName === 'addCustomer') {
        this.addCustomer = !this.addCustomer
      } else if (actionName === 'machinesManagement') {
        this.machinesManagement = !this.machinesManagement
      } else if (actionName === 'editCustomer') {
        this.editCustomer = !this.editCustomer
        // console.log(this.company)
      } else if (actionName === 'documents') {
        this.documents = !this.documents
      } else if (actionName === 'platformSettings') {
        this.platformSettings = !this.platformSettings
      }
    },
    currentUserRole () {
      const userRole = this.$store.state.role
      if (userRole === '100100') {
        return 'admin'
      } else if (userRole === 'user') {
        return 'user'
      }
    }
  }
}
</script>

<style scoped>
#id {
  background-color: var(--v-background-base);
}
.menu-list {
  color: #222831;
  background: #222831;
}

</style>
