<template>
  <div class="text-center">
    <v-dialog
      :value="openMyProfile"
      width="900"
      @click:outside="closeDialog"
    >
      <v-card>
        <v-card-title class="text-h5 secondary-background white--text">
          <v-icon
            class="mr-3"
            medium
            color="#ffa849"
          >
            mdi-account-circle-outline
          </v-icon>
          Můj profil
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
              md="6"
              cols="12"
              class="mt-10"
            >
              <v-text-field
                v-model="company"
                disabled
                prepend-icon="mdi-domain"
                outlined
                dense
                elevation="0"
                label="Společnost"
              />
            </v-col>
            <v-col
              md="6"
              cols="12"
              class="mt-6 text-center"
            >
              <v-img
                v-if="$store.state.company === 'Systechgroup'"
                style="max-width: 280px; margin: 0 auto;"
                src="../assets/systech.png"
              />
              <v-img
                v-else
                style="max-width: 280px; max-height: 80px; margin: 10px auto 0 auto;"
                :src="fileURL + $store.state.userDetails.companyLogo"
              />
            </v-col>
          </v-row>
          <div class="headline mb-3">
            Uživatel
          </div>
          <v-row>
            <v-col
              md="6"
              cols="12"
              class="mt-0"
            >
              <v-text-field
                v-model="userName"
                outlined
                style="margin-bottom: -30px;"
                prepend-icon="mdi-account"
                dense
                label="Jméno a Přijmení"
              />
            </v-col>
            <v-col
              md="6"
              cols="12"
              class="mt-0 text-right"
            >
              <v-text-field
                v-model="userEmail"
                outlined
                style="margin-bottom: -10px;"
                dense
                prepend-icon="mdi-email"
                label="Email"
              />
              <div
                class="red--text"
                style="cursor: pointer;"
                @click="logoutAndResetPassword"
              >
                Změnit heslo?
              </div>
            </v-col>
          </v-row>
          <div class="headline mb-3">
            Adresa
          </div>
          <v-row>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="street"
                outlined
                dense
                prepend-icon="mdi-map-marker"
                label="Ulice"
                style="margin-bottom: -30px;"
              />
            </v-col>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="city"
                outlined
                dense
                prepend-icon="mdi-city"
                label="Město"
                style="margin-bottom: -30px;"
              />
            </v-col>
          </v-row>
          <v-row
            class="mb-3"
          >
            <v-col
              md="6"
              cols="12"
              class="mt-1"
            >
              <v-text-field
                v-model="country"
                outlined
                dense
                prepend-icon="mdi-flag"
                label="Země"
                style="margin-bottom: -30px;"
              />
            </v-col>
            <v-col
              md="6"
              cols="12"
              class="mt-1"
            >
              <v-text-field
                v-model="postCode"
                outlined
                dense
                prepend-icon="mdi-code-array"
                style="margin-bottom: -30px;"
                label="PSČ"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn

            :disabled="loading"
            color="primary"
            text
            @click="closeDialog"
          >
            Zrušit
          </v-btn>
          <v-spacer />
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="tertiary"
            text
            @click="saveMyProfile"
          >
            Uložit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    openMyProfile: Boolean
  },
  data () {
    return {
      loading: false,
      street: '',
      city: '',
      postCode: '',
      country: '',
      userEmail: '',
      userName: '',
      company: '',
      fileURL: process.env.VUE_APP_API_FILES
    }
  },
  watch: {
    openMyProfile: function (val) {
      if (val) {
        this.updateUserData()
      }
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    },
    updateUserData () {
      this.street = this.$store.state.address.street
      this.city = this.$store.state.address.city
      this.postCode = this.$store.state.address.postCode
      this.country = this.$store.state.address.country
      this.userEmail = this.$store.state.userEmail
      this.userName = this.$store.state.userName
      this.company = this.$store.state.company
    },
    saveMyProfile () {
      this.loading = true
      const editedData = {
        userName: this.userName,
        userEmail: this.userEmail,
        userAddress: {
          city: this.city,
          street: this.street,
          country: this.country,
          postCode: this.postCode
        }
      }
      this.$store.dispatch('saveMyProfile', editedData)
        .then(resp => {
          this.loading = false
          this.closeDialog()
        })
        .catch(err => {
          this.errorMessage = err.response.data
        })
    },
    logoutAndResetPassword () {
      this.$store.dispatch('logout')
      this.closeDialog()
      this.$router.push('/forgot-password')
    }
  }
}
</script>
