<template>
  <v-container class="page-container d-flex">
    <v-row>
      <v-col
        md="5"
        sm="12"
        xs="12"
        class="login-box"
      >
        <div
          class="inner-box text--gray"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <div class="headline mb-2">
              Přihlášení
            </div>
            <v-text-field
              v-model="email"
              prepend-icon="mdi-email"
              type="email"
              dense
              outlined
              :rules="emailRules"
              label="E-mail"
              style=""
              required
            />

            <v-text-field
              v-model="password"
              outlined
              dense
              :rules="passwordRules"
              prepend-icon="mdi-lock"
              type="password"
              label="Heslo"
              required
            />
            <v-row>
              <v-col>
                <div class="mt-3">
                  <router-link
                    to="/forgot-password"
                    style="text-decoration: none; color: #ff5722;"
                  >
                    Zapomenuté heslo?
                  </router-link>
                  <router-view />
                </div>
              </v-col>
              <v-col class="text-right">
                <v-btn
                  :loading="loading"
                  class="white--text"
                  :disabled="!valid || loading"
                  color="#ff5722"
                  @click="validate"
                >
                  Přihlásit
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-col>
      <v-col
        md="7"
        sm="12"
        xs="12"
        class="login-box"
      >
        <div class="inner-box d-flex align-center">
          <div class="text--gray">
            <span class="headline">Vítejte</span><br>
            <p> na stránkách systému pro správu Vašich strojů Modula. Pro vstup do systému je nutné se přihlásit.</p><p>V případě, že dosud nemá Vaše firma zřízený účet, nebo se Vám nedaří přihlásit, kontaktuje prosím administrátora systému na email:</p> <a
              href="mailto:obchod@systechgroup.eu"
              style="text-decoration: none; color: #ff5722;"
            >obchod@systechgroup.eu</a>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-scroll-y-transition>
      <v-alert
        v-if="errorMessage"
        color="red"
        type="error"
        class="alert"
        border="left"
      >
        {{ errorMessage.slice(2) }}
      </v-alert>
    </v-scroll-y-transition>
  </v-container>
</template>

<script>

export default {
  name: 'LoginPage',
  data: () => ({
    valid: false,
    loading: false,
    email: null,
    emailRules: [
      v => !!v || 'Email je povinny',
      v => /.+@.+\..+/.test(v) || 'Email musi mit spravny format'
    ],
    password: null,
    passwordRules: [
      v => !!v || 'Heslo je povinne'
    ],
    errorMessage: ''
  }),

  methods: {
    validate () {
      this.$refs.form.validate()
      if (this.valid) {
        this.loginUser()
      }
    },
    loginUser () {
      this.$store.dispatch('loginUser', { userEmail: this.email, userPassword: this.password })
        .then(resp => {
          this.$store.dispatch('getUserData').then(() => {
            this.$router.push('/portal')
          })
        })
        .catch(err => {
          this.errorMessage = err.response.data
          this.password = null
          setTimeout(() => {
            this.errorMessage = ''
          }, 2000)
        })
    }
  }

}
</script>

<style scoped>
.alert {
  position: absolute;
  top: -90px;
  z-index: 10;
  left: 50%;
  min-width: 350px;
  transform: translate(-50%, 50%);
}

</style>
