<template>
  <v-container class="page-container d-flex">
    <v-row no-gutters>
      <v-col
        class="login-box justify-center"
      >
        <v-alert
          v-if="errorMessage.length"
          color="secondary"
          dismissible
          style="position: absolute; z-index: 10; left: 50%; top: 50%; max-width: 350px; transform: translate(-40%, 50%);"
          type="error"
          @input="closeError"
        >
          {{ errorMessage }}
        </v-alert>
        <div v-if="!loading && canContinue">
          <v-card
            elevation="0"
            class="padding: 40px;"
            style="max-width: 500px; margin: 35px auto; background: white;"
          >
            <v-card-text
              v-if="selectedView === '1'"
              class="padding: 30px; "
            >
              <div class="headline mb-5">
                Zapomenuté heslo
              </div>

              <v-text-field
                v-model="password1"
                label="Heslo"
                outlined
                dense
                type="password"
                required
              />
              <v-text-field
                v-model="password2"
                label="Potvrďte heslo"
                outlined
                dense
                type="password"
                required
              />
            </v-card-text>
            <v-card-text v-else-if="selectedView === '2'">
              <div>
                Heslo zmeneno, prosim pokracujte na prihlaseni
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="selectedView === '1'"
                color="primary"
                :dark="loadingSave ? false : true"
                :loading="loading"
                :disabled="loading || loadingSave"
                @click="BtnBack"
              >
                Zpět
              </v-btn>
              <v-spacer />
              <v-btn
                v-if="selectedView === '1'"
                :loading="loadingSave"
                :disabled="loading && password1 !== password2"
                :dark="loadingSave ? false : true"
                color="tertiary"
                @click="changePassword"
              >
                Odeslat
              </v-btn>
              <v-btn
                v-if="selectedView === '2'"
                color="tertiary"
                dark
                @click="goToSignIn"
              >
                Pokracovat
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
        <div v-else-if="!loading && !canContinue">
          Nesprávný token
        </div>
        <div v-else-if="loading">
          Načítání
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'ForgotPassword',
  data () {
    return {

      password1: null,
      password2: null,
      canContinue: false,
      loading: true,
      loadingSave: false,
      errorMessage: '',
      selectedView: '1'
    }
  },
  created () {
    const token = this.$route.params.token
    this.$store.dispatch('verifyToken', token)
      .then(resp => {
        this.canContinue = true
        this.loading = false
      })
      .catch(err => {
        console.log(err)
        this.loading = false
      }

      )
  },
  methods: {
    BtnBack () {
      this.$router.push('/')
    },
    changePassword () {
      this.loadingSave = true
      const token = this.$route.params.token
      this.$store.dispatch('changePassword', { token, password: this.password1 })
        .then(resp => {
          this.loadingSave = false
          this.selectedView = '2'
        })
        .catch(error => {
          this.errorMessage = error.response.data
          this.loadingSave = false
        })
    },
    goToSignIn () {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>
