<template>
  <div class="text-center">
    <v-dialog
      :value="editCustomer"
      width="1200"
      scrollable
      :persistent="loading || unsavedItems || uploading || uploadingLogo"
      @click:outside="closeDialog"
    >
      <v-alert
        v-if="errorMessage.length"
        color="secondary"
        dismissible
        style="position: absolute; z-index: 10; left: 50%; top: 50%; max-width: 350px; transform: translate(-40%, 50%);"
        type="error"
        @input="closeError"
      >
        {{ errorMessage }}
      </v-alert>
      <v-alert
        v-if="successMessage.length"
        dismissible
        style="position: absolute; z-index: 10; left: 50%; top: 50%; max-width: 350px; transform: translate(-40%, 50%);"
        type="success"
        @input="closeSuccess"
      >
        {{ successMessage }}
      </v-alert>
      <v-card :disabled="loading || uploading || uploadingLogo">
        <v-card-title
          v-if="selectedView === 1"
          class="text-h5 secondary-background white--text"
        >
          <v-icon
            class="mr-3"
            medium
            color="#ffa849"
          >
            mdi-account-supervisor
          </v-icon>
          Všichni zákazníci
        </v-card-title>
        <v-card-title
          v-else
          class="text-h5 secondary-background white--text"
        >
          <v-icon
            class="mr-3"
            medium
            color="#ffa849"
          >
            mdi-account-edit
          </v-icon>
          Detail a úprava zákazníka
        </v-card-title>

        <v-progress-linear
          v-if="loading"
          indeterminate
          color="tertiary"
          class="mb-0"
        />
        <v-card-text>
          <v-expand-transition mode="out-in">
            <div
              v-if="selectedView === 1"
              key="1"
            >
              <v-data-table
                :headers="headers"
                :search="search"
                :items="filteredCustomers"
                sort-by="id"
                :items-per-page="10"
                class="elevation-0 mt-3"
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    label="Hledat zákazníka"
                    class="mx-4"
                  />
                </template>
                <template v-slot:item.company="{ item }">
                  <v-icon
                    color="secondary"
                    style="position: relative; top: -2px;"
                  >
                    mdi-domain
                  </v-icon>
                  {{ item.company }}
                </template>
                <template v-slot:item.userName="{ item }">
                  <v-icon
                    style="position: relative; top: -2px;"
                    color="tertiary"
                  >
                    mdi-account
                  </v-icon>
                  {{ item.userName }}
                </template>
                <template v-slot:top>
                  <v-dialog
                    v-model="dialogDelete"
                    max-width="300px"
                  >
                    <v-card>
                      <v-card-title>
                        Deleting user
                      </v-card-title>
                      <v-card-text>
                        <div v-if="!loading">
                          Opravdu si přejete odstranit uživatele?
                        </div>
                        <div v-else-if="loading">
                          Načítání...
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn

                          :disabled="loading"
                          text
                          @click="dialogDelete = false"
                        >
                          Zrušit
                        </v-btn>
                        <v-btn

                          :disabled="loading"
                          color="tertiary"
                          text
                          @click="deleteCustomer()"
                        >
                          Odstranit
                        </v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                <template v-slot:item.customerEdit="{ item }">
                  <v-btn

                    :disabled="loading"
                    small
                    color="primary"
                    icon
                    class="mr-2"
                    dark
                    @click="editUser(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-icon
                    color="red"

                    @click="deleteCustomerVerify(item.id)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </div>

            <div
              v-else-if="selectedView === 2"
              key="2"
            >
              <v-row>
                <v-col
                  cols="12"
                  class="pa-0 ma-0"
                >
                  <v-col mode="out-in">
                    <div

                      class="text--dark-gray mt-1"
                    >
                      <div class="headline">
                        Firma
                      </div>
                      <v-row class="ml-2 mt-1 mb-1">
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="companyName"
                            color="tertiary"
                            outlined
                            style="margin-bottom: -30px;"
                            class="custom-label-color"
                            prepend-icon="mdi-card-account-details"
                            dense
                            label="Název firmy"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-file-input
                            v-if="!companyLogoLink && !uploadingLogo"
                            v-model="newCompanyLogo"
                            outlined
                            accept="image/png, image/jpeg, image/bmp"
                            class="custom-label-color"
                            prepend-icon="mdi-image"
                            label="Logo"
                            style="margin-bottom: -30px;"
                            dense
                          />
                          <v-progress-circular
                            v-else-if="uploadingLogo"
                            style="margin: 0 auto;"
                            indeterminate
                            color="tertiary"
                          />
                          <div
                            v-else
                            style="position: relative; max-height: 45px; max-width: 200px; margin: 0 auto;"
                          >
                            <img
                              style="max-height: 85px; max-width: 200px; margin: 0 auto;"
                              :src="fileURL + companyLogoLink"
                            >

                            <v-icon
                              color="red"
                              small
                              style="position: absolute; top: -5px; right: -20px;"
                              @click="deleteCompanyLogo"
                            >
                              mdi-close
                            </v-icon>
                          </div>
                        </v-col>
                      </v-row>
                      <div class="headline  ">
                        Adresa
                      </div>
                      <v-row class="ml-2 mt-1">
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="companyStreet"
                            color="tertiary"
                            outlined
                            dense
                            class="custom-label-color"
                            prepend-icon="mdi-map-marker"
                            label="Ulice"
                            style="margin-bottom: -30px;"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="companyCity"
                            color="tertiary"
                            outlined
                            dense
                            class="custom-label-color"
                            prepend-icon="mdi-city"
                            label="Město"
                            style="margin-bottom: -30px;"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="ml-2 mt-1">
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="companyCountry"
                            color="tertiary"
                            outlined
                            dense
                            class="custom-label-color"
                            prepend-icon="mdi-flag"
                            label="Země"
                            style="margin-bottom: -30px;"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="companyPostCode"
                            color="tertiary"
                            outlined
                            dense
                            class="custom-label-color"
                            prepend-icon="mdi-code-array"
                            style="margin-bottom: -30px;"
                            label="PSČ"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="ml-2 mt-1 mb-1">
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="companyIC"
                            color="tertiary"
                            outlined
                            class="custom-label-color"
                            dense
                            prepend-icon="mdi-numeric"
                            label="IČ"
                            style="margin-bottom: -30px;"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="companyDIC"
                            color="tertiary"
                            outlined
                            dense
                            class="custom-label-color"
                            prepend-icon="mdi-counter"
                            style="margin-bottom: -30px;"
                            label="DIČ"
                          />
                        </v-col>
                      </v-row>
                      <div class="headline  ">
                        Zodpovědná osoba
                      </div>
                      <v-row class="ml-2 mt-1 ">
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="customerName"
                            class="custom-label-color"
                            color="tertiary"
                            outlined
                            dense
                            prepend-icon="mdi-account"
                            style="margin-bottom: -30px;"
                            label="Jméno"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="ml-2 mt-1 mb-1">
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="customerEmail"
                            color="tertiary"
                            class="custom-label-color"
                            outlined
                            dense
                            prepend-icon="mdi-email"
                            style="margin-bottom: -30px;"
                            label="E-mail"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="customerPhoneNumber"
                            color="tertiary"
                            class="custom-label-color"
                            outlined
                            dense
                            prepend-icon="mdi-phone"
                            style="margin-bottom: -30px;"
                            label="Tel. číslo"
                          />
                        </v-col>
                      </v-row>
                      <div class="headline">
                        Poznámky
                      </div>
                      <v-row class="ml-2 mt-1 mb-1">
                        <v-col cols="12">
                          <v-textarea
                            v-model="companyComment"
                            color="tertiary"
                            outlined
                            class="custom-label-color"
                            no-resize
                            height="80px"
                            label="Poznámky"
                          />
                        </v-col>
                      </v-row>
                      <div class="headline">
                        Stroje

                        <v-btn
                          text
                          color="tertiary"
                          style="float: right;"
                          :loading="loading"
                          :disabled="loading"
                          @click="selectedView = 4; getAllMachines();"
                        >
                          Přidat stroj +
                        </v-btn>
                      </div>
                      <v-row class="ml-2 mt-1 mb-1">
                        <v-col

                          cols="12"
                        >
                          <v-expansion-panels>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                Stroje
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-col
                                  v-for="machine in machines"

                                  :key="machine.id"
                                >
                                  {{ machine.machineModel }} - {{ machine.machineType }}
                                  <div style="float:right;">
                                    <v-btn
                                      :loading="loading"
                                      :disabled="loading"
                                      small
                                      color="tertiary"
                                      class="mr-2"
                                      dark
                                      @click="editMachine(machine);selectedView = 3"
                                    >
                                      Upravit
                                    </v-btn>
                                    <v-icon
                                      color="red"

                                      @click="deleteCustomerMachineVerify(machine.id)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                    <v-dialog
                                      v-model="dialogDelete2"
                                      max-width="300px"
                                    >
                                      <v-card>
                                        <v-card-title>
                                          Deleting machine
                                        </v-card-title>
                                        <v-card-text>
                                          <div v-if="!loading">
                                            Opravdu si přejete odstranit stroj?
                                          </div>
                                          <div v-else-if="loading">
                                            Načítání...
                                          </div>
                                        </v-card-text>
                                        <v-card-actions>
                                          <v-spacer />
                                          <v-btn

                                            :disabled="loading"
                                            text
                                            @click="dialogDelete2 = false"
                                          >
                                            Zrušit
                                          </v-btn>
                                          <v-btn

                                            :disabled="loading"
                                            color="tertiary"
                                            text
                                            @click="deleteCustomerMachine()"
                                          >
                                            Odstranit
                                          </v-btn>
                                          <v-spacer />
                                        </v-card-actions>
                                      </v-card>
                                    </v-dialog>
                                  </div>
                                </v-col>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                      </v-row>
                      <div
                        class="headline"
                      >
                        Soubory
                      </div>
                      <v-row
                        class="ml-2 mt-1"
                        style="margin-bottom: -45px;"
                      >
                        <v-col cols="12">
                          <div style="position: relative">
                            <v-file-input
                              v-model="newFiles"
                              color="tertiary"
                              class="custom-label-color"
                              label="Nové soubory"
                              multiple
                              outlined
                              :disabled="uploading"
                              dense
                            />
                            <v-progress-circular
                              v-if="uploading"
                              style="margin: 0 auto; position: absolute; top: 4px;; left: 50%;"
                              color="tertiary"
                              indeterminate
                            />
                          </div>
                          <v-row class="mb-1">
                            <div
                              v-for="file in files"
                              :key="file"
                              style="cursor: pointer; border: 1px solid lightgray; border-radius: 5px; width: 150px; text-align: center; position: relative; margin: 5px; padding: 5px;"
                              class="hoover"
                              @click="openLink(file)"
                            >
                              <v-icon
                                :color="mapFileExtension(file).color"
                                style="font-size: 85px;"
                              >
                                {{ mapFileExtension(file).icon }}
                              </v-icon>
                              <div style="text-decoration: underline; font-size: 12px; text-align: center;">
                                {{ mapFileName(file) }}
                              </div>
                              <v-icon
                                color="red"
                                small
                                style="position: absolute; top: 0px; right: 0px;"
                                @click="$event.stopPropagation(); deleteFile(file);"
                              >
                                mdi-close
                              </v-icon>
                            </div>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-col>
              </v-row>
            </div>
            <div
              v-else-if="selectedView === 3"
              key="5"
            >
              <v-row class="mt-1">
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="selectedMachine.machineType"
                    color="tertiary"
                    outlined
                    dense
                    prepend-icon="mdi-cog-outline"
                    style="margin-bottom: -30px;"
                    label="Typ stoje"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="selectedMachine.machineModel"
                    color="tertiary"
                    outlined
                    dense
                    prepend-icon="mdi-content-save-cog-outline"
                    style="margin-bottom: -30px;"
                    label="Model stroje"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div
                    style="padding: 10px; border: 1px solid lightgray; border-radius: 5px;"
                  >
                    <div style="width: 100%; text-align: center;">
                      <img
                        :src="fileURL + selectedMachine.machineImage"
                        style="max-height: 150px;"
                      >
                    </div>
                  </div>
                </v-col>
              </v-row>
              <div class="headline black--text mt-5">
                Seriové číslo
              </div>
              <v-row class="mt-1">
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="selectedMachine.machineSerialNumber"
                    color="tertiary"
                    outlined
                    dense
                    prepend-icon="mdi-numeric"
                    style="margin-bottom: -30px;"
                    label="Sériové číslo"
                  />
                </v-col>
              </v-row>
              <div class="headline black--text mt-5">
                Datum
              </div>
              <v-row class="mt-1">
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="selectedMachine.installationDate"
                    color="tertiary"
                    outlined
                    dense
                    prepend-icon="mdi-calendar-range"
                    style="margin-bottom: -30px;"
                    label="Datum instalace"
                    @click="openPickerOne"
                  />
                  <v-date-picker
                    v-if="pickerOne"
                    v-model="selectedMachine.installationDate"
                    locale="sk-SK"
                    elevation="2"

                    header-color="#3d5a81"
                    color="tertiary"

                    class="position-absolute"
                    @input="pickerOne=false"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="selectedMachine.dateOfManufacture"
                    color="tertiary"
                    outlined
                    dense
                    prepend-icon="mdi-calendar-range"
                    style="margin-bottom: -30px;"
                    label="Datum výroby"
                    @click="openPickerTwo"
                  />
                  <v-date-picker
                    v-if="pickerTwo"
                    v-model="selectedMachine.dateOfManufacture"
                    locale="sk-SK"
                    elevation="2"
                    header-color="#3d5a81"

                    color="tertiary"
                    class="position-absolute"

                    @input="pickerTwo=false"
                  />
                </v-col>
              </v-row>
              <div class="headline black--text mt-5">
                Informace
              </div>
              <v-row class="mt-1">
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="selectedMachine.machineCycles"
                    color="tertiary"
                    outlined
                    dense
                    min="0"
                    type="number"
                    prepend-icon="mdi-counter"
                    style="margin-bottom: -30px;"
                    label="Počet cyklů"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="selectedMachine.machineIP"
                    color="tertiary"
                    outlined
                    dense
                    prepend-icon="mdi-laptop"
                    style="margin-bottom: -30px;"
                    label="IP Adresa"
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="selectedMachine.machineShelves"
                    color="tertiary"
                    outlined
                    dense
                    number
                    prepend-icon="mdi-numeric"
                    style="margin-bottom: -30px;"
                    label="Počet polic"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="selectedMachine.serviceEveryXDays"
                    color="tertiary"
                    outlined
                    dense
                    type="number"
                    number
                    prepend-icon="mdi-account-wrench"
                    style="margin-bottom: -30px;"
                    label="Servis kazdych (dnu)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="selectedMachine.serviceEveryXCycles"
                    color="tertiary"
                    outlined
                    type="number"
                    dense
                    number
                    prepend-icon="mdi-account-wrench"
                    style="margin-bottom: -30px;"
                    label="Anebo Servis kazdych (pocet cyklu)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="selectedMachine.serviceContract"
                    color="tertiary"
                    outlined
                    type="text"
                    dense
                    prepend-icon="mdi-file-sign"
                    style="margin-bottom: -30px;"
                    label="Servisni smlouva"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-checkbox
                    v-model="selectedMachine.additionalAccessoriesEnabled"
                    label="Doplňková výbava"
                    color="tertiary"
                  />
                  <v-combobox
                    v-if="selectedMachine.additionalAccessoriesEnabled === true"
                    v-model="selectedMachine.machineAccessories"
                    :items="machineAccessoriesData"
                    chips
                    elevation="0"
                    label="Doplňková výbava"
                    multiple
                    outlined
                    dense
                    prepend-icon="mdi-filter-variant"
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                        color="secondary"
                        dark
                        style="margin-top: 5px;"
                        small
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeMachineAccessory(item)"
                      >
                        <strong>{{ item }}</strong>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="selectedMachine.notes"
                    color="tertiary"
                    :disabled="loading"
                    outlined
                    no-resize
                    height="80px"
                    label="Poznámky"
                  />
                </v-col>
                <v-col cols="12">
                  <v-divider />
                  <v-checkbox
                    v-model="addressIsDifferent"
                    label="Adresa stroje je jina nez adresa zakaznika"
                    color="tertiary"
                  />

                  <div v-if="addressIsDifferent">
                    <v-row class="mt-1">
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="selectedMachine.machineAddress.street"
                          color="tertiary"
                          :disabled="loading"
                          outlined
                          dense
                          prepend-icon="mdi-map-marker"
                          label="Ulice"
                          style="margin-bottom: -30px;"
                          :rules="rules"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="selectedMachine.machineAddress.city"
                          color="tertiary"
                          outlined
                          :disabled="loading"
                          dense
                          prepend-icon="mdi-city"
                          label="Město"
                          style="margin-bottom: -30px;"
                          :rules="rules"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="mt-1">
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="selectedMachine.machineAddress.country"
                          color="tertiary"
                          outlined
                          :disabled="loading"
                          dense
                          prepend-icon="mdi-flag"
                          label="Země"
                          style="margin-bottom: -30px;"
                          :rules="rules"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="selectedMachine.machineAddress.postCode"
                          :disabled="loading"
                          color="tertiary"
                          outlined
                          dense
                          prepend-icon="mdi-code-array"
                          style="margin-bottom: -30px;"
                          label="PSČ"
                          :rules="rules"
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <div class="mt-5">
                        <v-text-field
                          v-model="selectedMachine.responsiblePerson"
                          prepend-icon="mdi-account"
                          color="tertiary"
                          outlined
                          dense
                          label="Zodpovedna osoba"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <div class="mt-5">
                        <v-text-field
                          v-model="selectedMachine.systechOrderNumber"
                          color="tertiary"
                          prepend-icon="mdi-receipt"
                          outlined
                          dense
                          label="Cislo objednavky"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-divider />
                  <div style="position: relative; margin-top: 20px;">
                    <v-file-input
                      v-model="selectedMachine.machineFiles"
                      color="tertiary"
                      class="custom-label-color"
                      label="Pridat nové soubory"
                      multiple
                      outlined
                      :disabled="uploading"
                      dense
                    />
                    <v-progress-circular
                      v-if="uploading"
                      style="margin: 0 auto; position: absolute; top: 4px;; left: 50%;"
                      color="tertiary"
                      indeterminate
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
            <div
              v-else-if="selectedView === 4"
              key="4"
            >
              <v-row
                class="mt-3 justify-center align-center"
              >
                <v-col
                  v-for="machine in allMachines"
                  :key="machine.id"
                  cols="3"
                  class="machine-tile"
                  style="vertical-align: middle; cursor: pointer;"
                  @click="selectMachineToCustomer(machine)"
                >
                  <div style="display: inline-block; text-align: center; width: 50%;">
                    <img
                      style="max-height: 130px;"
                      :src="fileURL + machine.machineImage"
                    >
                  </div>
                  <div
                    style="display: inline-block; padding-left: 10px; width: 50%; vertical-align:top;"
                  >
                    <div class="font-weight-bold">
                      {{ machine.machineType }}
                    </div>
                    <div>
                      {{ machine.machineModel }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div
              v-else-if="selectedView === 5"
              key="5000000"
            >
              <v-row class="mt-1">
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="selectedMachineToAdd.machineType"
                    color="tertiary"
                    outlined
                    dense
                    prepend-icon="mdi-cog-outline"
                    style="margin-bottom: -30px;"
                    label="Typ stoje"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="selectedMachineToAdd.machineModel"
                    color="tertiary"
                    outlined
                    dense
                    prepend-icon="mdi-content-save-cog-outline"
                    style="margin-bottom: -30px;"
                    label="Model stroje"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div
                    style="padding: 10px;"
                  >
                    <div style="width: 100%; text-align: center;">
                      <img
                        :src="fileURL + selectedMachineToAdd.machineImage"
                        style="max-height: 150px;"
                      >
                    </div>
                  </div>
                </v-col>
              </v-row>
              <div class="headline black--text mt-5">
                Seriové číslo
              </div>
              <v-row class="mt-1">
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="selectedMachineToAdd.machineSerialNumber"
                    color="tertiary"
                    outlined
                    dense
                    prepend-icon="mdi-numeric"
                    style="margin-bottom: -30px;"
                    label="Sériové číslo"
                  />
                </v-col>
              </v-row>
              <div class="headline black--text mt-5">
                Datum
              </div>
              <v-row class="mt-1">
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="installationDate"
                    color="tertiary"
                    outlined
                    dense
                    prepend-icon="mdi-calendar-range"
                    style="margin-bottom: -30px;"
                    label="Datum instalace"
                    @click="openPickerOne"
                  />
                  <v-date-picker
                    v-if="pickerOne"
                    v-model="installationDate"
                    locale="sk-SK"
                    elevation="2"

                    header-color="#3d5a81"
                    color="tertiary"

                    class="position-absolute"
                    @input="pickerOne=false"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="dateOfManufacture"
                    color="tertiary"
                    outlined
                    dense
                    prepend-icon="mdi-calendar-range"
                    style="margin-bottom: -30px;"
                    label="Datum výroby"
                    @click="openPickerTwo"
                  />
                  <v-date-picker
                    v-if="pickerTwo"
                    v-model="dateOfManufacture"
                    locale="sk-SK"
                    elevation="2"
                    header-color="#3d5a81"

                    color="tertiary"
                    class="position-absolute"

                    @input="pickerTwo=false"
                  />
                </v-col>
              </v-row>
              <div class="headline black--text mt-5">
                Informace
              </div>
              <v-row class="mt-1">
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="selectedMachineToAdd.machineCycles"
                    color="tertiary"
                    outlined
                    dense
                    min="0"
                    type="number"
                    prepend-icon="mdi-counter"
                    style="margin-bottom: -30px;"
                    label="Počet cyklů"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="selectedMachineToAdd.machineIP"
                    color="tertiary"
                    outlined
                    dense
                    prepend-icon="mdi-laptop"
                    style="margin-bottom: -30px;"
                    label="IP Adresa"
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="selectedMachineToAdd.machineShelves"
                    color="tertiary"
                    outlined
                    dense
                    number
                    prepend-icon="mdi-numeric"
                    style="margin-bottom: -30px;"
                    label="Počet polic"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="selectedMachineToAdd.serviceEveryXDays"
                    color="tertiary"
                    outlined
                    dense
                    type="number"
                    number
                    prepend-icon="mdi-account-wrench"
                    style="margin-bottom: -30px;"
                    label="Servis kazdych (dnu)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="selectedMachineToAdd.serviceEveryXCycles"
                    color="tertiary"
                    outlined
                    type="number"
                    dense
                    number
                    prepend-icon="mdi-account-wrench"
                    style="margin-bottom: -30px;"
                    label="Anebo Servis kazdych (pocet cyklu)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="selectedMachineToAdd.serviceContract"
                    color="tertiary"
                    outlined
                    type="text"
                    dense
                    prepend-icon="mdi-file-sign"
                    style="margin-bottom: -30px;"
                    label="Servisni Smlouva"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-checkbox
                    v-model="selectedMachineToAdd.additionalAccessoriesEnabled"
                    label="Doplňková výbava"
                    color="tertiary"
                  />
                  <v-combobox
                    v-if="selectedMachineToAdd.additionalAccessoriesEnabled === true"
                    v-model="selectedMachineToAdd.machineAccessories"
                    :items="machineAccessoriesData"
                    chips
                    elevation="0"
                    label="Doplňková výbava"
                    multiple
                    outlined
                    dense
                    prepend-icon="mdi-filter-variant"
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                        color="secondary"
                        dark
                        style="margin-top: 5px;"
                        small
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeMachineAccessory(item)"
                      >
                        <strong>{{ item }}</strong>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="selectedMachineToAdd.notes"
                    color="tertiary"
                    :disabled="loading"
                    outlined
                    no-resize
                    height="80px"
                    label="Poznámky"
                    value="Zde je prostor pro doplnění, případné poznámky."
                  />
                </v-col>
                <v-col cols="12">
                  <v-divider />
                  <v-checkbox
                    v-model="selectedMachineToAdd.addressIsDifferent"
                    label="Adresa stroje je jina nez adresa zakaznika"
                    color="tertiary"
                  />

                  <div v-if="selectedMachineToAdd.addressIsDifferent">
                    <v-row class="mt-1">
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="selectedMachineToAdd.machineAddress.street"
                          color="tertiary"
                          :disabled="loading"
                          outlined
                          dense
                          prepend-icon="mdi-map-marker"
                          label="Ulice"
                          style="margin-bottom: -30px;"
                          :rules="rules"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="selectedMachineToAdd.machineAddress.city"
                          color="tertiary"
                          outlined
                          :disabled="loading"
                          dense
                          prepend-icon="mdi-city"
                          label="Město"
                          style="margin-bottom: -30px;"
                          :rules="rules"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="mt-1">
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="selectedMachineToAdd.machineAddress.country"
                          color="tertiary"
                          outlined
                          :disabled="loading"
                          dense
                          prepend-icon="mdi-flag"
                          label="Země"
                          style="margin-bottom: -30px;"
                          :rules="rules"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="selectedMachineToAdd.machineAddress.postcode"
                          :disabled="loading"
                          color="tertiary"
                          outlined
                          dense
                          prepend-icon="mdi-code-array"
                          style="margin-bottom: -30px;"
                          label="PSČ"
                          :rules="rules"
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <div class="mt-5">
                        <v-text-field
                          v-model="selectedMachineToAdd.responsiblePerson"
                          prepend-icon="mdi-account"
                          color="tertiary"
                          outlined
                          dense
                          label="Zodpovedna osoba"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <div class="mt-5">
                        <v-text-field
                          v-model="selectedMachineToAdd.systechOrderNumber"
                          color="tertiary"
                          prepend-icon="mdi-receipt"
                          outlined
                          dense
                          label="Cislo objednavky"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-divider />
                  <div style="position: relative; margin-top: 20px;">
                    <v-file-input
                      v-model="selectedMachineToAdd.machineFiles"
                      color="tertiary"
                      class="custom-label-color"
                      label="Pridat nové soubory"
                      multiple
                      outlined
                      :disabled="uploading"
                      dense
                    />
                    <v-progress-circular
                      v-if="uploading"
                      style="margin: 0 auto; position: absolute; top: 4px;; left: 50%;"
                      color="tertiary"
                      indeterminate
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            v-if="selectedView !== 1 && selectedView !== 3"
            color="primary"
            text
            :disabled="loading || unsavedItems || uploadingLogo || uploading"
            @click="clearPage"
          >
            Zpět
          </v-btn>
          <v-btn
            v-if="selectedView === 3"
            color="primary"
            text
            :disabled="loading || unsavedItems || uploadingLogo || uploading"
            @click="selectedView = 2"
          >
            Zpět
          </v-btn>
          <v-btn
            v-else
            color="primary"
            :disabled="loading || unsavedItems || uploadingLogo || uploading"
            text
            @click="closeDialog"
          >
            Zavřít
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="selectedView !== 1 && selectedView !== 3 && selectedView !== 5 "
            color="tertiary"

            :disabled="loading|| uploadingLogo || uploading"
            text
            @click="saveEditedCustomer"
          >
            Uložit zákazníka
          </v-btn>
          <v-btn
            v-if="selectedView === 3 "

            color="tertiary"
            :disabled="loading"
            text
            @click="saveEditedMachine"
          >
            Uložit Stroj
          </v-btn>
          <v-btn
            v-if="selectedView === 5"
            :loading="loading"
            color="tertiary"
            :disabled="loading"
            text
            @click="addMachineToExistingCustomer"
          >
            Přidat Stroj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import dayjs from 'dayjs'
export default {
  filters: {
    uppercase: function (value) {
      return value.toUpperCase()
    }
  },
  props: {
    editCustomer: Boolean

  },
  data () {
    return {
      lodash: _,

      loading: false,
      dialogDelete: false,
      dialogDelete2: false,
      userId: null,
      unsavedItems: false,
      uploadingLogo: false,
      uploading: false,
      errorMessage: '',
      successMessage: '',
      companyStreet: '',
      search: '',
      companyCity: '',
      companyPostCode: '',
      companyCountry: '',
      companyName: '',
      companyIC: '',
      companyDIC: '',
      customerName: '',
      customerSurname: '',
      customerEmail: '',
      customerPhoneNumber: '',
      companyComment: '',
      companyLogoLink: '',
      newCompanyLogo: null,
      selectedView: 1,
      customerEdit: '',
      newFiles: null,
      files: '',
      machineAccessoriesData: [],
      pickerOne: false,
      pickerTwo: false,
      addressIsDifferent: false,
      installationDate: dayjs().format('YYYY-MM-DD'),
      dateOfManufacture: dayjs().format('YYYY-MM-DD'),
      allMachines: [],
      customerId: null,
      selectedMachine: {},
      selectedMachineToAdd: {},
      fileURL: process.env.VUE_APP_API_FILES,
      machineIdDelete: null,
      headers: [
        {
          text: '#',
          value: 'id',
          align: 'start'
        },
        {
          text: 'Společnost',
          value: 'company',
          align: 'start'
        },
        { text: 'Zodpovědná osoba', value: 'userName', align: 'center' },
        { text: 'ICO', value: 'ic', align: 'center' },
        { text: 'Email', value: 'userEmail', align: 'center' },
        { text: 'Detail', value: 'customerEdit', sortable: false, align: 'end' }
      ]
    }
  },
  computed: {
    filteredCustomers () {
      const items = this.$store.state.allPortalUsers
      return items.filter(item => {
        return item.company.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    machines () {
      return this.$store.state.customerMachines.filter(machine => machine.userId === this.userId)
    }
  },
  watch: {
    selectedView: function (val) {
      console.log(val)
    },
    newCompanyLogo: function (val) {
      if (val !== null) {
        this.unsavedItems = true
        this.uploadingLogo = true
        const fileData = new FormData()
        fileData.append('logo', this.newCompanyLogo)
        this.$store.dispatch('uploadFile', fileData)
          .then(resp => {
            // console.log(resp.data)
            this.companyLogoLink = resp.data
            this.uploadingLogo = false
          })
          .catch(err => {
            this.errorMessage = err.response.data
            this.uploadingLogo = false
            this.companyLogo = null
          })
      }
    },
    newFiles: function (val) {
      if (val !== null) {
        this.unsavedItems = true
        this.uploading = true
        const fileData = new FormData()
        for (const file of this.newFiles) {
          fileData.append('files', file)
        }
        this.$store.dispatch('uploadFiles', fileData)
          .then(resp => {
            resp.data.forEach(item => {
              this.files.push(item)
            })
            this.uploading = false
            this.newFiles = null
          })
          .catch(err => {
            this.errorMessage = err.response.data
            this.uploading = false
            this.loading = false
            this.newFiles = null
          })
      }
    }
  },
  methods: {
    // console (item) {
    //   console.log(item)
    // },
    closeDialog () {
      if (this.unsavedItems) {
        this.errorMessage = 'Mate neulozene zmeny, prosim ulozte pred pokracovanim'
      } else if (this.uploadingLogo || this.uploading) {
        this.errorMessage = 'Probiha upload, prosim pockejte'
      } else {
        this.$emit('close')
        this.selectedView = 1
      }
    },
    deleteCustomerMachine () {
      this.loading = true
      this.$store.dispatch('deleteCustomerMachine', this.machineIdDelete)
        .then(resp => {
          this.dialogDelete2 = false
          this.loading = false
        })
        .catch(err => {
          this.errorMessage = err.response.data
        })
    },
    deleteCustomerMachineVerify (machineId) {
      this.machineIdDelete = machineId
      this.dialogDelete2 = true
    },
    deleteCustomer () {
      this.loading = true
      this.$store.dispatch('deleteCustomer', this.customerId)
        .then(resp => {
          this.dialogDelete = false
          this.loading = false
        })
        .catch(err => {
          this.errorMessage = err.response.data
        })
    },
    deleteCustomerVerify (customerId) {
      this.dialogDelete = true
      this.customerId = customerId
    },
    openPickerOne () {
      this.pickerOne = !this.pickerOne
    },
    openPickerTwo () {
      this.pickerTwo = !this.pickerTwo
    },
    editMachine (machine) {
      this.selectedMachine = machine
      const machineStreet = machine.machineAddress.street
      const companyStreet = this.companyStreet
      if (machineStreet !== companyStreet) {
        this.addressIsDifferent = true
      }
      this.$store.dispatch('getMachineDetails')
        .then(resp => {
          this.machineAccessoriesData = resp.data.accessories
          // console.log(resp.data.accessories)
        })
        .catch(err => {
          this.loading = false
          this.errorMessage = err.response.data
        })
    },
    getAllMachines () {
      this.$store.dispatch('getAllMachines')
        .then(resp => {
          this.allMachines = resp.data
        })
        .catch(err => {
          this.loading = false
          this.errorMessage = err.response.data
        })
    },
    selectMachineToCustomer (machine) {
      this.selectedMachineToAdd = { ...machine }
      this.selectedMachineToAdd.notes = ''
      this.selectedMachineToAdd.responsiblePerson = this.customerName
      this.selectedMachineToAdd.customer = this.companyName
      this.selectedMachineToAdd.systechOrderNumber = ''
      this.selectedMachineToAdd.machineFiles = null
      this.selectedMachineToAdd.machineAddress = { street: '', city: '', postCode: '', country: '' }
      this.selectedMachineToAdd.addressIsDifferent = false
      // console.log(this.selectedMachineToAdd)
      this.selectedView = 5
    },
    addMachineToExistingCustomer () {
      this.loading = true
      if (!this.selectedMachineToAdd.addressIsDifferent) {
        this.selectedMachineToAdd.machineAddress = { street: this.companyStreet, city: this.companyCity, postCode: this.companyPostCode, country: this.companyCountry }
      }
      this.$store.dispatch('addMachineToExistingCustomer', { machine: this.selectedMachineToAdd, userId: this.userId })
        .then(resp => {
          this.selectedView = 2
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.errorMessage = err.response.data
        })
    },
    removeMachineAccessory (item) {
      const index = this.selectedMachine.machineAccessories.findIndex(i => i === item)
      this.selectedMachine.machineAccessories.splice(index, 1)
    },
    editUser (item) {
      // console.log(item)
      this.loading = true
      // this.$store.dispatch('getSpecificCustomerData').then(resp => { // TO-DO

      // })
      this.selectedView = 2
      this.userId = item.id
      this.companyStreet = item.userAddress.street
      this.companyCity = item.userAddress.city
      this.companyPostCode = item.userAddress.postCode
      this.companyCountry = item.userAddress.country
      this.companyLogoLink = item.companyLogo
      this.companyName = item.company
      this.companyIC = item.ic
      this.companyDIC = item.dic
      this.customerName = item.userName
      this.files = item.files
      this.customerEmail = item.userEmail
      this.customerPhoneNumber = item.userPhoneNumber
      this.companyComment = item.notes
      this.loading = false
    },
    mapFileExtension (file) {
      const splitted = file.split('.')
      const extension = splitted.pop().toLowerCase()
      // console.log(extension)
      if (extension === 'pdf') {
        return { icon: 'mdi-file-pdf-box', color: 'red' }
      } else if (extension === 'doc' || extension === 'docx' || extension === 'txt') {
        return { icon: 'mdi-file-word-box', color: 'blue' }
      } else if (extension === 'xls' || extension === 'xlsx' || extension === 'csv') {
        return { icon: 'mdi-file-excel-box', color: 'green' }
      } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
        return { icon: 'mdi-file-image', color: 'purple' }
      } else {
        return { icon: 'mdi-file', color: 'gray' }
      }
    },
    mapFileName (file) {
      const string = file.substring(19)
      const length = 22
      return string.length > length
        ? string.substring(0, length) + '...'
        : string
    },
    openLink (link) {
      window.open(`https://systechgroup-service.warberryapps.com/files/${link}`, '_blank')
    },
    clearPage () {
      this.selectedView = 1
      this.companyStreet = ''
      this.companyCity = ''
      this.companyPostCode = ''
      this.companyCountry = ''
      this.companyLogoLink = ''
      this.companyName = ''
      this.companyIC = ''
      this.companyDIC = ''
      this.customerName = ''
      this.files = ''
      this.customerEmail = ''
      this.customerPhoneNumber = ''
      this.companyComment = ''
      this.loading = false
    },
    deleteCompanyLogo () {
      this.companyLogoLink = ''
    },
    deleteFile (file) {
      // console.log(typeof this.files)
      const index = this.files.findIndex(item => item === file)
      if (index !== -1) {
        this.unsavedItems = true
        this.files.splice(index, 1)
      }
      // this.$store.dispatch('removeFile', file) // TO-DO
    },
    closeError () {
      this.errorMessage = ''
    },
    saveEditedMachine () {
      this.$store.dispatch('editedCustomerMachine', this.selectedMachine)
        .then(resp => {
          this.loading = false
          this.unsavedItems = false
          this.successMessage = 'Stroj ulozen'
          setTimeout(() => {
            this.successMessage = ''
          }, 2000)
          this.selectedView = 2
        })
        .catch(err => {
          this.loading = false
          this.errorMessage = err.response.data
        })
    },
    saveEditedCustomer () {
      this.loading = true
      const edited = {
        userId: this.userId,
        address: {
          street: this.companyStreet,
          city: this.companyCity,
          postCode: this.companyPostCode,
          country: this.companyCountry
        },
        companyLogo: this.companyLogoLink,
        company: this.companyName,
        ic: this.companyIC,
        dic: this.companyDIC,
        userName: this.customerName,
        files: this.files,
        userEmail: this.customerEmail,
        userPhoneNumber: this.customerPhoneNumber,
        notes: this.companyComment
      }
      // console.log(edited)
      this.$store.dispatch('saveEditedCustomer', edited)
        .then(resp => {
          this.loading = false
          this.unsavedItems = false
          this.successMessage = 'Zakaznik ulozen'
          setTimeout(() => {
            this.successMessage = ''
          }, 2000)
        })
        .catch(err => {
          this.loading = false
          this.errorMessage = err.response.data
        })
    },
    closeSuccess () {
      this.successMessage = ''
    }
  }
}
</script>

<style scoped>
.hoover:hover {
  background-color: rgba(128, 128, 128, 0.145);
}

.machine-tile {
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin: 5px;
}
.machine-tile:hover {
  border-color: #ff5722;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
</style>
