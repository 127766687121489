<template>
  <div class="text-center">
    <v-dialog
      :value="machinesManagement"
      width="1200"
      scrollable
      :persistent="loading || unsavedItems || uploading"
      @click:outside="closeDialog"
    >
      <v-alert
        v-if="errorMessage.length"
        color="secondary"
        dismissible
        style="position: absolute; z-index: 10; left: 50%; top: 50%; max-width: 350px; transform: translate(-40%, 50%);"
        type="error"
        @input="closeError"
      >
        {{ errorMessage }}
      </v-alert>
      <v-alert
        v-if="successMessage.length"
        dismissible
        style="position: absolute; z-index: 10; left: 50%; top: 50%; max-width: 350px; transform: translate(-40%, 50%);"
        type="success"
        @input="closeSuccess"
      >
        {{ successMessage }}
      </v-alert>
      <v-card>
        <v-card-title class="text-h5 secondary-background white--text">
          <v-icon
            class="mr-3"
            medium
            color="#ffa849"
          >
            mdi-cog
          </v-icon>
          <div v-if="selectedView !== 3">
            Stroje
          </div>
          <div v-else>
            {{ machineEditView.machineType }} - {{ machineEditView.machineModel }}
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-if="selectedView !== 3"
              sm="3"
              cols="12"
              class="pa-0 ma-0 mt-3"
            >
              <v-list class="hidden-xs-only pr-3">
                <v-list-item
                  style=" border-radius: 5px;"
                  :class="selectedView === 1 ? 'active-button white--text' : ''"
                  @click="selectedView = 1; clearData();"
                >
                  <v-icon
                    class="mr-3"
                    :class="selectedView === 1 ? 'white--text' : ''"
                  >
                    mdi-cog-outline
                  </v-icon>
                  Stroje
                </v-list-item>
                <v-list-item
                  style=" border-radius: 5px;"
                  color="secondary"
                  :class="selectedView === 2 ? 'active-button white--text' : ''"
                  @click="selectedView = 2; clearData();"
                >
                  <v-icon
                    class="mr-3"
                    :class="selectedView === 2 ? 'white--text' : ''"
                  >
                    mdi-folder-plus-outline
                  </v-icon>
                  Vytvořit stroj
                </v-list-item>
              </v-list>
              <div class="hidden-sm-and-up mt-2">
                <v-btn
                  text
                  dense
                  :class="selectedView === 1 ? 'active-button white--text' : ''"
                  @click="selectedView = 1; clearData();"
                >
                  <v-icon
                    class="mr-1"
                    :class="selectedView === 1 ? 'white--text' : ''"
                  >
                    mdi-cog-outline
                  </v-icon>
                  Stroje
                </v-btn>

                <v-btn
                  text
                  dense
                  :class="selectedView === 2 ? 'active-button white--text' : ''"
                  @click="selectedView = 2; clearData();"
                >
                  <v-icon
                    class="mr-1"
                    :class="selectedView === 2 ? 'white--text' : ''"
                  >
                    mdi-folder-plus-outline
                  </v-icon>
                  Vytvořit stroj
                </v-btn>
              </div>
            </v-col>
            <v-divider
              v-if="selectedView === 1"
              vertical
            />
            <v-col>
              <v-expand-transition mode="out-in">
                <!-- list -->
                <div
                  v-if="selectedView === 1"
                  key="1"
                >
                  <div>
                    <v-data-table
                      :headers="headers"
                      :search="search"
                      :items="allMachines"
                      :items-per-page="10"
                      class="elevation-0 mt-3"
                    >
                      <template v-slot:top>
                        <v-text-field
                          v-model="search"
                          label="Hledat stroj"
                          class="mx-4"
                        />
                      </template>
                      <template v-slot:item.company="{ item }">
                        <v-icon
                          color="secondary"
                          style="position: relative; top: -2px;"
                        >
                          mdi-domain
                        </v-icon>
                        {{ item.machineType }}
                      </template>

                      <template v-slot:item.machineImage="{ item }">
                        <img
                          style="max-height: 50px; margin-top: 5px;"
                          :src="fileURL + item.machineImage"
                        >
                      </template>
                      <template v-slot:top>
                        <v-dialog
                          v-model="dialogDelete"
                          max-width="300px"
                        >
                          <v-card>
                            <v-card-title>
                              Odstaneni stroje
                            </v-card-title>
                            <v-card-text>
                              <div v-if="!loading">
                                Opravdu si přejete odstranit stroj?
                              </div>
                              <div v-else-if="loading">
                                Načítání...
                              </div>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer />
                              <v-btn

                                :disabled="loading"
                                text
                                @click="dialogDelete = false"
                              >
                                Zrušit
                              </v-btn>
                              <v-btn

                                :disabled="loading"
                                color="tertiary"
                                text
                                @click="deleteMachine()"
                              >
                                Odstranit
                              </v-btn>
                              <v-spacer />
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </template>
                      <template v-slot:item.machineEdit="{ item }">
                        <v-btn
                          :loading="loading"
                          :disabled="loading"
                          small
                          color="primary"
                          class="mr-2"
                          dark
                          icon
                          @click="openMachineToEdit(item); selectedView = 3;"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-icon
                          color="red"

                          @click="deleteMachineVerify(item.id)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </div>
                </div>
                <!-- create -->
                <div
                  v-else-if="selectedView === 2"
                  key="2"
                  class="text--dark-gray mt-5"
                >
                  <div class="headline  ">
                    Novy Stroj
                  </div>
                  <v-row class="mt-1">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="machineType"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-cog-outline"
                        style="margin-bottom: -30px;"
                        label="Typ stoje"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="machineModel"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-content-save-cog-outline"
                        style="margin-bottom: -30px;"
                        label="Model stroje"
                      />
                    </v-col>
                  </v-row>
                  <div class="headline black--text mt-5">
                    Obrázek stroje
                    <v-icon
                      v-if="machineImagesLink !== ''"
                      color="tertiary"
                      @click="deleteMachineImage"
                    >
                      mdi-close-box
                    </v-icon>
                  </div>
                  <v-row class="mt-1">
                    <v-col
                      v-if="machineImagesLink === ''"

                      style="margin: 0 auto;"
                    >
                      <v-file-input
                        v-model="machineImages"
                        :disabled="loading"
                        accept="image/png, image/jpeg, image/bmp"
                        prepend-icon="mdi-image"
                        color="tertiary"
                        label="Obrázek stroje"
                        outlined
                        dense
                      />
                    </v-col>
                    <div v-if="uploading">
                      Načítání...
                    </div>
                    <div
                      v-else-if="machineImagesLink !== ''"
                      style="margin: 0 auto; max-width: 100px"
                    >
                      <img

                        :src="fileURL + machineImagesLink"
                        style="max-height: 150px;"
                      >
                    </div>
                  </v-row>
                  <div class="headline black--text mt-5">
                    Seriové číslo
                  </div>
                  <v-row class="mt-1">
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="machineSerialNumber"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-numeric"
                        style="margin-bottom: -30px;"
                        label="Sériové číslo"
                      />
                    </v-col>
                  </v-row>
                  <div class="headline black--text mt-5">
                    Datum
                  </div>
                  <v-row class="mt-1">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="installationDate"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-calendar-range"
                        style="margin-bottom: -30px;"
                        label="Datum instalace"
                        @click="openPickerOne"
                      />
                      <v-date-picker
                        v-if="pickerOne"
                        v-model="installationDate"
                        locale="sk-SK"
                        elevation="2"

                        header-color="#3d5a81"
                        color="tertiary"

                        class="position-absolute"
                        @input="pickerOne=false"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="dateOfManufacture"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-calendar-range"
                        style="margin-bottom: -30px;"
                        label="Datum výroby"
                        @click="openPickerTwo"
                      />
                      <v-date-picker
                        v-if="pickerTwo"
                        v-model="dateOfManufacture"
                        locale="sk-SK"
                        elevation="2"
                        header-color="#3d5a81"

                        color="tertiary"
                        class="position-absolute"

                        @input="pickerTwo=false"
                      />
                    </v-col>
                  </v-row>
                  <div class="headline black--text mt-5">
                    Informace
                  </div>
                  <v-row class="mt-1">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="machineCycles"
                        color="tertiary"
                        outlined
                        dense
                        min="0"
                        type="number"
                        prepend-icon="mdi-counter"
                        style="margin-bottom: -30px;"
                        label="Počet cyklů"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="machineIP"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-laptop"
                        style="margin-bottom: -30px;"
                        label="IP Adresa"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="machineShelves"
                        color="tertiary"
                        outlined
                        dense
                        number
                        prepend-icon="mdi-numeric"
                        style="margin-bottom: -30px;"
                        label="Počet polic"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="serviceEveryXDays"
                        color="tertiary"
                        outlined
                        dense
                        type="number"
                        number
                        prepend-icon="mdi-account-wrench"
                        style="margin-bottom: -30px;"
                        label="Servis kazdych (dnu)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="serviceEveryXCycles"
                        color="tertiary"
                        outlined
                        type="number"
                        dense
                        number
                        prepend-icon="mdi-account-wrench"
                        style="margin-bottom: -30px;"
                        label="Anebo Servis kazdych (pocet cyklu)"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-checkbox
                        v-model="additionalAccessoriesEnabled"
                        label="Doplňková výbava"
                        color="tertiary"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-combobox
                        v-if="additionalAccessoriesEnabled === true"
                        v-model="machineAccessories"
                        :items="machineAccessoriesData"
                        chips
                        outlined
                        dense

                        clearable
                        label="Doplňková výbava"
                        multiple
                        prepend-icon="mdi-filter-variant"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-chip
                              color="secondary"
                              label
                              small
                              class="float-end"
                            >
                              {{ search }}
                            </v-chip>
                          </v-list-item>
                        </template>
                        <template v-slot:selection="{ attrs, item, select, selected }">
                          <v-chip
                            color="secondary"
                            dark
                            small
                            style="margin-top: 5px;"
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            @click="select"
                            @click:close="removeMachineAccessory(item)"
                          >
                            <strong>{{ item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>

                    <v-col cols="12">
                      <!--   <div class="file-upload">
                        <div>
                          <div class="file-upload-inner">
                            <v-icon
                              color="tertiary"
                              x-large
                            >
                              mdi-cloud-upload-outline
                            </v-icon>
                          </div>
                          <div>Zde přetáhněte soubory</div>
                        </div>
                      </div>-->
                      <v-file-input
                        v-model="files"
                        label="Nahrat soubory"
                        multiple
                        outlined
                        dense
                        prepend-icon="mdi-paperclip"
                      >
                        <template v-slot:selection="{ text }">
                          <v-chip
                            small
                            label
                            color="primary"
                          >
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                </div>
                <!-- edit / view -->
                <div
                  v-else-if="selectedView === 3"
                  key="3"
                >
                  <v-row class="mt-1">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="machineEditView.machineType"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-cog-outline"
                        style="margin-bottom: -30px;"
                        label="Typ stoje"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="machineEditView.machineModel"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-content-save-cog-outline"
                        style="margin-bottom: -30px;"
                        label="Model stroje"
                      />
                    </v-col>
                  </v-row>

                  <div class="headline black--text mt-5">
                    Obrázek stroje
                    <v-icon
                      v-if="machineEditView.machineImage !== ''"
                      color="tertiary"
                      @click="deleteMachineImageEdit"
                    >
                      mdi-close-box
                    </v-icon>
                  </div>
                  <v-row class="mt-1">
                    <v-col
                      v-if="machineEditView.machineImage === ''"

                      style="margin: 0 auto;"
                    >
                      <v-file-input
                        v-model="editedMachineImages"
                        :disabled="loading"
                        accept="image/png, image/jpeg, image/bmp"
                        prepend-icon="mdi-image"
                        color="tertiary"
                        label="Obrázek stroje"
                        outlined
                        dense
                      />
                    </v-col>
                    <v-col
                      v-else
                      style="margin: 0 auto; max-width: 100px"
                    >
                      <v-img

                        :src="fileURL + machineEditView.machineImage"
                        style="max-height: 150px;"
                      />
                    </v-col>
                  </v-row>
                  <div class="headline black--text mt-5">
                    Seriové číslo
                  </div>
                  <v-row class="mt-1">
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="machineEditView.machineSerialNumber"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-numeric"
                        style="margin-bottom: -30px;"
                        label="Sériové číslo"
                      />
                    </v-col>
                  </v-row>
                  <div class="headline black--text mt-5">
                    Datum
                  </div>
                  <v-row class="mt-1">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="installationDate"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-calendar-range"
                        style="margin-bottom: -30px;"
                        label="Datum instalace"
                        @click="openPickerOne"
                      />
                      <v-date-picker
                        v-if="pickerOne"
                        v-model="installationDate"
                        locale="sk-SK"
                        elevation="2"

                        header-color="#3d5a81"
                        color="tertiary"

                        class="position-absolute"
                        @input="pickerOne=false"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="dateOfManufacture"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-calendar-range"
                        style="margin-bottom: -30px;"
                        label="Datum výroby"
                        @click="openPickerTwo"
                      />
                      <v-date-picker
                        v-if="pickerTwo"
                        v-model="dateOfManufacture"
                        locale="sk-SK"
                        elevation="2"
                        header-color="#3d5a81"

                        color="tertiary"
                        class="position-absolute"

                        @input="pickerTwo=false"
                      />
                    </v-col>
                  </v-row>
                  <div class="headline black--text mt-5">
                    Informace
                  </div>
                  <v-row class="mt-1">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="machineEditView.machineCycles"
                        color="tertiary"
                        outlined
                        dense
                        min="0"
                        type="number"
                        prepend-icon="mdi-counter"
                        style="margin-bottom: -30px;"
                        label="Počet cyklů"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="machineEditView.machineIP"
                        color="tertiary"
                        outlined
                        dense
                        prepend-icon="mdi-laptop"
                        style="margin-bottom: -30px;"
                        label="IP Adresa"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="machineEditView.machineShelves"
                        color="tertiary"
                        outlined
                        dense
                        number
                        prepend-icon="mdi-numeric"
                        style="margin-bottom: -30px;"
                        label="Počet polic"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="machineEditView.serviceEveryXDays"
                        color="tertiary"
                        outlined
                        dense
                        type="number"
                        number
                        prepend-icon="mdi-account-wrench"
                        style="margin-bottom: -30px;"
                        label="Servis kazdych (dnu)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="machineEditView.serviceEveryXCycles"
                        color="tertiary"
                        outlined
                        type="number"
                        dense
                        number
                        prepend-icon="mdi-account-wrench"
                        style="margin-bottom: -30px;"
                        label="Anebo Servis kazdych (pocet cyklu)"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-checkbox
                        v-model="machineEditView.additionalAccessoriesEnabled"
                        label="Doplňková výbava"
                        color="tertiary"
                      />
                      <v-combobox
                        v-if="machineEditView.additionalAccessoriesEnabled === true"
                        v-model="machineEditView.machineAccessories"
                        :items="machineAccessoriesData"
                        chips
                        elevation="0"
                        label="Doplňková výbava"
                        multiple
                        outlined
                        dense
                        prepend-icon="mdi-filter-variant"
                      >
                        <template v-slot:selection="{ attrs, item, select, selected }">
                          <v-chip
                            color="secondary"
                            dark
                            style="margin-top: 5px;"
                            small
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            @click="select"
                            @click:close="removeMachineAccessory(item)"
                          >
                            <strong>{{ item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                      <div style="position: relative; margin-top: 20px;">
                        <v-file-input
                          v-model="newFiles"
                          color="tertiary"
                          class="custom-label-color"
                          label="Pridat nové soubory"
                          multiple
                          outlined
                          :disabled="uploading"
                          dense
                        />
                        <v-progress-circular
                          v-if="uploading"
                          style="margin: 0 auto; position: absolute; top: 4px;; left: 50%;"
                          color="tertiary"
                          indeterminate
                        />
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    class="ml-2 mt-1"
                    style="margin-bottom: -45px;"
                  >
                    <v-col cols="12">
                      <v-row class="mb-5">
                        <div
                          v-for="file in machineEditView.files"
                          :key="file"
                          style="cursor: pointer; border: 1px solid lightgray; border-radius: 5px; width: 150px; text-align: center; position: relative; margin: 5px; padding: 5px;"
                          class="hoover"
                          @click="openLink(file)"
                        >
                          <v-icon
                            :color="mapFileExtension(file).color"
                            style="font-size: 85px;"
                          >
                            {{ mapFileExtension(file).icon }}
                          </v-icon>
                          <div style="text-decoration: underline; font-size: 12px; text-align: center;">
                            {{ mapFileName(file) }}
                          </div>
                          <v-icon
                            color="red"
                            small
                            style="position: absolute; top: 0px; right: 0px;"
                            @click="$event.stopPropagation(); deleteFile(file);"
                          >
                            mdi-close
                          </v-icon>
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            v-if="selectedView !== 3"
            color="primary"
            text
            :loading="loading"
            :disabled="loading"
            @click="closeDialog"
          >
            Zrušit
          </v-btn>
          <v-btn
            v-if="selectedView === 3"
            color="primary"
            :loading="loading"
            :disabled="loading || uploading || unsavedItems"
            text
            @click="selectedView = 1; clearData();"
          >
            Zpet
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="selectedView === 2 && !loading"
            color="tertiary"
            text
            :loading="loading"
            :disabled="loading"
            @click="submit"
          >
            Přidat
          </v-btn>
          <v-progress-circular
            v-if="loading"
            color="tertiary"
            indeterminate
          />
          <v-btn
            v-if="selectedView === 3 && !loading"
            color="tertiary"
            text
            :loading="loading"
            :disabled="uploading || loading"
            @click="saveEditedMachine"
          >
            Uložit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {

  props: { machinesManagement: Boolean },
  data () {
    return {
      machineEditView: null,
      unsavedItems: false,
      additionalAccessoriesEnabled: false,
      search: '',
      loading: false,
      uploading: false,
      selectedView: 1,
      installationDate: dayjs().format('YYYY-MM-DD'),
      dateOfManufacture: dayjs().format('YYYY-MM-DD'),
      pickerOne: false,
      pickerTwo: false,
      newFiles: null,
      files: null,
      dialogDelete: false,
      successMessage: '',
      errorMessage: '',
      machineType: '',
      machineAccessories: [],
      machineAccessoriesData: [],
      machineModel: '',
      machineSerialNumber: '',
      machineCycles: 0,
      machineIP: '',
      machineImages: null,
      machineImagesLink: '',
      editedMachineImages: null,
      editedMachineImagesLink: '',
      machineShelves: 0,
      serviceEveryXDays: 365,
      serviceEveryXCycles: 10000,
      fileURL: process.env.VUE_APP_API_FILES,
      allMachines: [],
      headers: [
        {
          text: '#',
          value: 'id',
          align: 'start'
        },
        {
          text: 'Typ stroje',
          value: 'machineType',
          align: 'start'
        },
        { text: 'Model stroje', value: 'machineModel', align: 'center' },
        { text: 'Obrazek', value: 'machineImage', align: 'center' },
        { text: 'Detail', value: 'machineEdit', sortable: false, align: 'end' }
      ]
    }
  },
  computed: {
  },
  watch: {
    machinesManagement: function (val) {
      if (val) {
        this.pullMachineDetails()
        this.pullAllMachines()
      }
    },
    newFiles: function (val) {
      if (val !== null) {
        this.unsavedItems = true
        this.uploading = true
        const fileData = new FormData()
        for (const file of this.newFiles) {
          fileData.append('files', file)
        }
        this.$store.dispatch('uploadFiles', fileData)
          .then(resp => {
            resp.data.forEach(item => {
              this.machineEditView.files.push(item)
            })
            this.uploading = false
            this.newFiles = null
          })
          .catch(err => {
            this.errorMessage = err.response.data
            this.uploading = false
            this.loading = false
            this.newFiles = null
          })
      }
    },
    machineImages: function (val) {
      if (val !== null) {
        this.uploadingLogo = true
        const fileData = new FormData()
        fileData.append('logo', this.machineImages)
        this.$store.dispatch('uploadFile', fileData)
          .then(resp => {
            // console.log(resp.data)
            this.machineImagesLink = resp.data
            this.uploadingLogo = false
          })
          .catch(err => {
            this.errorMessage = err.response.data
            this.uploadingLogo = false
            this.machineImages = null
          })
      }
    }
    // : function (val) {
    //   if (val !== null) {
    //     this.uploadingLogo = true
    //     const fileData = new FormData()
    //     fileData.append('logo', this.machineImages)
    //     this.$store.dispatch('uploadFile', fileData)
    //       .then(resp => {
    //         // console.log(resp.data)
    //         this.machineImagesLink = resp.data
    //         this.uploadingLogo = false
    //       })
    //       .catch(err => {
    //         this.errorMessage = err.response.data
    //         this.uploadingLogo = false
    //         this.machineImages = null
    //       })
    //   }
    // }

  },
  methods: {
    closeDialog () {
      if (this.unsavedItems) {
        this.errorMessage = 'Mate neulozene zmeny, prosim ulozte pred pokracovanim'
      } else if (this.uploading) {
        this.errorMessage = 'Probiha upload, prosim pockejte'
      } else {
        this.$emit('close')
        this.clearData()
        this.selectedView = 1
      }
    },
    openPickerOne () {
      this.pickerOne = !this.pickerOne
    },
    openPickerTwo () {
      this.pickerTwo = !this.pickerTwo
    },
    submit () {
      this.loading = true

      const newMachine = {
        machineType: this.machineType,
        machineAccessories: this.machineAccessories,
        machineModel: this.machineModel,
        machineSerialNumber: this.machineSerialNumber,
        machineCycles: this.machineCycles,
        machineIP: this.machineIP,
        machineShelves: this.machineShelves,
        installationDate: this.installationDate,
        dateOfManufacture: this.dateOfManufacture,
        additionalAccessoriesEnabled: this.additionalAccessoriesEnabled,
        serviceEveryXDays: this.serviceEveryXDays,
        serviceEveryXCycles: this.serviceEveryXCycles,
        machineImage: this.machineImagesLink
      }
      if (this.files === null) {
        newMachine.files = []
        this.createMachine(newMachine)
      } else {
        this.uploading = true
        const fileData = new FormData()
        for (const file of this.files) {
          fileData.append('files', file)
        }
        this.$store.dispatch('uploadFiles', fileData)
          .then(resp => {
            // if file uploaded successfully you will receive array of links then needs to be passed to createNewCustomer
            // console.log(resp.data)
            newMachine.files = resp.data
            this.createMachine(newMachine)
          })
          .catch(err => {
            this.errorMessage = err.response.data
            this.loading = false
            this.files = null
            this.uploading = false
          })
      }
    },
    createMachine (newMachine) {
      this.$store.dispatch('createMachines', newMachine)
        .then(resp => {
          this.allMachines.push(resp.data)
          this.loading = false
          this.uploading = false
          this.successMessage = 'Stroj vytvoren'
          this.selectedView = 1
          setTimeout(() => {
            this.successMessage = ''
          }, 2000)
          this.clearData()
        })
        .catch(err => {
          this.errorMessage = err.response.data
          this.loading = false
          this.uploading = false
        })
    },
    deleteMachine () {
      this.loading = true
      this.$store.dispatch('deleteMachine', this.machineId)
        .then(resp => {
          const index = this.allMachines.findIndex(item => item === this.machineId)
          this.allMachines.splice(index, 1)
          this.dialogDelete = false
          this.loading = false
        })
        .catch(err => {
          this.errorMessage = err.response.data
        })
    },
    deleteMachineVerify (machineId) {
      this.dialogDelete = true
      this.machineId = machineId
    },
    clearData () {
      this.machineType = ''
      this.machineAccessories = ''
      this.machineModel = ''
      this.machineSerialNumber = ''
      this.machineCycles = ''
      this.machineIP = ''
      this.machineShelves = ''
      this.dateOfManufacture = dayjs().format('YYYY-MM-DD')
      this.installationDate = dayjs().format('YYYY-MM-DD')
      this.serviceEveryXDays = null
      this.serviceEveryXCycles = null
      this.additionalAccessoriesEnabled = false
      this.machineImages = null
      this.machineImagesLink = ''
    },
    deleteMachineImage () {
      this.machineImagesLink = ''
      this.machineImages = null
    },
    deleteMachineImageEdit () {
      this.machineEditView.machineImage = ''
    },
    saveEditedMachine () {
      this.loading = true
      const editedMachine = {
        id: this.machineEditView.id,
        machineType: this.machineEditView.machineType,
        machineAccessories: this.machineEditView.machineAccessories,
        files: this.machineEditView.files,
        machineModel: this.machineEditView.machineModel,
        machineSerialNumber: this.machineEditView.machineSerialNumber,
        machineCycles: this.machineEditView.machineCycles,
        machineIP: this.machineEditView.machineIP,
        machineShelves: this.machineEditView.machineShelves,
        installationDate: this.installationDate,
        dateOfManufacture: this.dateOfManufacture,
        additionalAccessoriesEnabled: this.machineEditView.additionalAccessoriesEnabled,
        serviceEveryXDays: this.machineEditView.serviceEveryXDays,
        serviceEveryXCycles: this.machineEditView.serviceEveryXCycles,
        machineImage: this.machineEditView.machineImage
      }

      // console.log('Const pohoda')
      this.uploadingLogo = true
      if (this.editedMachineImages) {
        const fileData = new FormData()
        fileData.append('files', this.editedMachineImages)
        this.$store.dispatch('uploadFiles', fileData)
          .then(resp => {
            const file = resp.data[0]
            // console.log(file)

            editedMachine.machineImage = file

            this.saveEditedMachineSend(editedMachine)

            this.uploadingLogo = false
          })
          .catch(err => {
            this.errorMessage = err
            this.uploadingLogo = false
            this.machineImages = null
          })
      } else {
        this.saveEditedMachineSend(editedMachine)
      }

      // console.log(editedMachine)
    },
    saveEditedMachineSend (editedMachine) {
      // console.log('call pohoda')
      this.$store.dispatch('saveEditedMachine', editedMachine)
        .then(resp => {
          this.successMessage = 'Stroj uložen'
          this.loading = false
          this.uploading = false
          this.unsavedItems = false
          // console.log('resp pohoda')
          this.pullAllMachines()
          setTimeout(() => {
            this.successMessage = ''
          }, 2000)
        })
        .catch(err => {
          this.errorMessage = err.response.data
        })
    },
    pullMachineDetails () {
      this.$store.dispatch('getMachineDetails')
        .then(resp => {
          // this.machineAccessoriesData = resp.data.accessories
          // console.log(resp.data.accessories)
        })
        .catch(err => {
          this.loading = false
          this.errorMessage = err.response.data
        })
    },
    pullAllMachines () {
      this.$store.dispatch('getAllMachines')
        .then(resp => {
          this.allMachines = resp.data
        })
        .catch(err => {
          this.loading = false
          this.errorMessage = err.response.data
        })
    },
    openMachineToEdit (machine) {
      // console.log(this.machineEditView)
      // console.log(machine)
      this.installationDate = dayjs(machine.installationDate).format('YYYY-MM-DD')
      this.dateOfManufacture = dayjs(machine.dateOfManufacture).format('YYYY-MM-DD')
      this.machineEditView = machine
    },
    removeMachineAccessory (item) {
      const index = this.machineAccessories.findIndex(i => i === item)
      this.machineAccessories.splice(index, 1)
    },
    closeSuccess () {
      this.successMessage = ''
    },
    closeError () {
      this.errorMessage = ''
    },
    mapFileExtension (file) {
      const splitted = file.split('.')
      const extension = splitted.pop().toLowerCase()
      if (extension === 'pdf') {
        return { icon: 'mdi-file-pdf-box', color: 'red' }
      } else if (extension === 'doc' || extension === 'docx' || extension === 'txt') {
        return { icon: 'mdi-file-word-box', color: 'blue' }
      } else if (extension === 'xls' || extension === 'xlsx' || extension === 'csv') {
        return { icon: 'mdi-file-excel-box', color: 'green' }
      } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
        return { icon: 'mdi-file-image', color: 'purple' }
      } else {
        return { icon: 'mdi-file', color: 'gray' }
      }
    },
    mapFileName (file) {
      const string = file.substring(19)
      const length = 22
      return string.length > length
        ? string.substring(0, length) + '...'
        : string
    },
    deleteFile (file) {
      // console.log(typeof this.files)
      const index = this.files.findIndex(item => item === file)
      if (index !== -1) {
        this.unsavedItems = true
        this.files.splice(index, 1)
      }
      // this.$store.dispatch('removeFile', file) // TO-DO
    },
    openLink (link) {
      window.open(`https://systechgroup-service.warberryapps.com/files/${link}`, '_blank')
    }
  }
}
</script>

<style>

</style>
