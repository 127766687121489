import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginPage from '../views/LoginPage.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import CompleteRegistration from '../views/CompleteRegistration.vue'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LoginPage',
    component: LoginPage,
    meta: {
      redirectWhenAuth: true
    }
  },
  {
    path: '/portal',
    name: 'Portal',
    component: () => import('../views/Portal.vue'),
    meta: {
      requiresUser: true
    }
  },
  {
    path: '/complete-registration/:token',
    name: 'CompleteRegistration',
    component: CompleteRegistration

  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword

  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword

  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.redirectWhenAuth)) {
    if (store.getters.loggedIn) {
      next({
        path: '/portal'
      })
    } else {
      next()
    }
  } else if (to.matched.some((record) => record.meta.requiresUser)) {
    if (!store.getters.loggedIn) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
