<template>
  <div class="text-center">
    <v-dialog
      :value="openPortalAdmins"
      width="900"
      max-height="900"
      @click:outside="closeDialog"
    >
      <v-card>
        <v-card-title
          class="text-h5 secondary-background white--text"
        >
          <v-icon
            class="mr-3"
            medium
            color="#ffa849"
          >
            mdi-account-supervisor
          </v-icon>
          Administrátoři
        </v-card-title>

        <v-progress-linear
          v-if="loading"
          indeterminate
          color="tertiary"
          class="mb-0"
        />

        <v-card-text>
          <v-expand-transition mode="out-in">
            <div
              v-if="!loading"
              key="1"
            >
              <div class="mt-3 mb-3 text-h6">
                Pozvat nového administrátora
              </div>
              <v-row>
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="userName"
                    outlined
                    prepend-icon="mdi-account"
                    dense
                    label="Jméno a Přijmení"
                    style="margin-bottom: -10px;"
                  />
                </v-col>

                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="userEmail"
                    outlined
                    prepend-icon="mdi-email"
                    dense
                    label="Email"
                    style="margin-bottom: -10px;"
                  />
                </v-col>
              </v-row>
              <div class="text-right">
                <v-btn
                  :disabled="loading"
                  color="tertiary"
                  dark
                  text
                  @click="inviteNewAdmin"
                >
                  <div v-if="!loadingSave">
                    Odeslat
                  </div>
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                  />
                </v-btn>
              </div>
              <div class="mt-3 mb-3 text-h6">
                Seznam administátorů
              </div>
              <v-data-table
                :headers="headers"
                :items="portalAdmins"
              >
                <template v-slot:item.userName="{ item }">
                  {{ item.userName }} <v-chip
                    v-if="currentUser() === item.userName"
                    small
                    color="secondary"
                  >
                    JÁ
                  </v-chip>
                </template>
                <template v-slot:item.active="{ item }">
                  <v-switch
                    v-model="item.active"
                    :disabled="currentUser() === item.userName"
                    color="tertiary"
                  />
                </template>
                <template v-slot:top>
                  <v-dialog
                    v-model="dialogDelete"
                    max-width="300px"
                  >
                    <v-card>
                      <v-card-title>
                        Deleting user
                      </v-card-title>
                      <v-card-text>
                        Opravdu si přejete odstranit uživatele?
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          :loading="loading"
                          :disabled="loading"
                          text
                          @click="dialogDelete = false; deleteAdminId = null"
                        >
                          Zrušit
                        </v-btn>
                        <v-btn
                          :loading="loading"
                          :disabled="loading"
                          color="tertiary"
                          text
                          @click="deleteAdminAction()"
                        >
                          Odstranit
                        </v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    :loading="loading"
                    icon
                    :disabled="currentUser() === item.userName || loading"
                    color="tertiary"
                    @click="deleteAdminConfirm(item.id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </div>

            <div
              v-else
              key="2"
              class="text-center"
            >
              Načítam...
            </div>
          </v-expand-transition>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="primary"
            text
            @click="closeDialog"
          >
            Zavřít
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-scroll-y-transition>
      <v-alert
        v-if="errorMessage"
        color="red"
        type="error"
        class="alert"
        border="left"
      >
        {{ errorMessage }}
      </v-alert>
    </v-scroll-y-transition>
  </div>
</template>

<script>
export default {
  props: {
    openPortalAdmins: Boolean
  },
  data () {
    return {
      loading: false,
      loadingSave: false,
      userName: '',
      userEmail: '',
      portalAdmins: [],
      errorMessage: '',
      dialogDelete: false,
      headers: [
        { text: '#', value: 'id' },
        { text: 'Jméno', value: 'userName' },
        { text: 'Email', value: 'userEmail' },
        { text: 'Aktivní', value: 'active' },
        { text: '', value: 'actions', sortable: false }
      ],
      deleteAdminId: null
    }
  },
  watch: {
    openPortalAdmins: function (val) {
      if (val) {
        this.pullPortalAdmins()
      }
    },
    portalAdmins: {
      deep: true,
      immediate: false,
      handler (val) {
        // console.log(val)
      }
    }
  },
  methods: {
    currentUser () {
      return this.$store.state.userName
    },
    closeDialog () {
      this.$emit('close')
    },
    pullPortalAdmins () {
      this.loading = true
      this.$store.dispatch('getPortalAdmins')
        .then(resp => {
          this.portalAdmins = resp.data
          this.loading = false
        })
        .catch(err => {
          this.errorMessage = 'Něco selhalo, prosím zkuste to znovu ' + err
          this.loading = false
        })
    },
    inviteNewAdmin () {
      this.loadingSave = true
      this.$store.dispatch('addNewAdmin', { userEmail: this.userEmail, userName: this.userName })
        .then(resp => {
          this.portalAdmins.push(resp.data)
          this.userName = ''
          this.userEmail = ''
          this.loadingSave = false
        })
        .catch(error => {
          this.loadingSave = false
          console.log(error)
        })
    },
    deleteAdminConfirm (id) {
      this.dialogDelete = true
      this.deleteAdminId = id
    },
    deleteAdminAction () {
      // this.$store.dispatch('deleteAdmin', this.deleteAdminId)
      //   .then(resp => {
      //     const index = this.portalAdmins.findIndex(item => item.id === this.deleteAdminId)
      //     this.portalAdmins.splice(index, 1)
      //   })
      this.dialogDelete = false
    }
  }
}
</script>
